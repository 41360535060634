// import React from "react";
// import '../../../CSS/SignUp/SignUp.css'

// function PopupForm() {
//   return (
//     <form
//     id="popupForm"
//     className="Popupform"
//     method="POST"
//     action="https://formspree.io/f/xldelbjj"
//     // action="https://formspree.io/f/xdkodkqr"
//   >
//         <h1 className="popupform-heading">Sign Up and Start Your Journey</h1>
//       <div className="popupform-container">
        
//         <div className="popupform-wrapper">
//           <div className="popupform-section">
//             <label htmlFor="">Name*</label>
//             <input
//              type="text" 
//              className="popupform-input"
//              placeholder="enter your name"/>
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="">Email*</label>
//             <input type="email" className="popupform-input" required placeholder="enter your email address"/>
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="">Phone Number*</label>
//             <input type="text" className="popupform-input" placeholder="mobile number" required />
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="">Preferred Course*</label>
//             <select
//               id="course"
//               name="course"
//               className="popupform-input"
//               required
//             >
//               <option value="Course you are interested in?">Course you are interested in?</option>
//               <option value="c-programming">C Programming</option>
//               <option value="cpp-programming">C++ Programming</option>
//               <option value="java-programming">Java Programming</option>
//               <option value="react-js">React JS</option>
//               <option value="python-programming">Python Programming</option>
//               <option value="machine-learning">Machine Learning</option>
//               <option value="angular">Angular</option>
//               <option value="node-js">Node.js</option>
//               <option value="data-structures">Data Structures</option>
//               <option value="data-science-analytics">
//                 Data Science/Data Analytics
//               </option>
//               <option value="ai">Artificial Intelligence</option>
//               <option value="testing">Manual & Automation Testing</option>
//               <option value="blockchain">Blockchain</option>
//               <option value="cloud-computing-aws">Cloud Computing - AWS</option>
//               <option value="digital-marketing">Digital Marketing</option>
//               <option value="fullstack-development">
//                 Full Stack Development
//               </option>
//               <option value="python-fullstack">Python Fullstack</option>
//               <option value="react-fullstack">React Fullstack</option>
//               <option value="java-fullstack">Java Fullstack</option>
//               <option value="aspnet-core-mvc">ASP.Net Core MVC Course</option>
//               <option value="hr-payroll-training">HR & Payroll Training</option>
//               <option value="process-associate">
//                 Process Associate (Supply Chain Management)
//               </option>
//               <option value="content-writing">Content Writing</option>
//               <option value="embedded-systems">Embedded Systems</option>
//               <option value="iot">Internet of Things (IoT)</option>
//               <option value="lt-panel-design">LT Panel Design</option>
//               <option value="ht-panel-design">HT Panel Design</option>
//             </select>
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="">Preferred Mode*</label>
//             <select
//              name="location" 
//              id="location"
//              className="popupform-input"
//              required
//              >
//               <option value="select a location">select a location</option>
//               <option value="offline">offline</option>
//               <option value="online">online</option>
//             </select>
//             </div>
//             <div className="popupform-section">
//                 <label htmlFor="">Preferred time to call</label>
//                <select 
//                name="time"
//                id="time"
//                className="popupform-input"
//                required
//                >
//                 <option value="select a Timeslot">select a Timeslot</option>
//                 <option value="9AM - 2AM">9:30am - 1pm</option>
//                 <option value="2pm - 7pm">1:30pm - 7:20pm</option>
            
//                </select>
//             </div>
//         </div>
//       </div>
//       </form>
//   );
// }

// export default PopupForm;


// import React from "react";
// import '../../../CSS/SignUp/SignUp.css';

// function PopupForm() {
//   return (
//     <form
//       id="popupForm"
//       className="Popupform"
//       method="POST"
//       action="https://formspree.io/f/xldelbjj"
//     >
//       <h1 className="popupform-heading">Sign Up and Start Your Journey</h1>
//       <div className="popupform-container">
//         <div className="popupform-wrapper">
//           <div className="popupform-section">
//             <label htmlFor="name">Name*</label>
//             <input
//               type="text"
//               id="name"
//               name="name" 
//               className="popupform-input"
//               placeholder="Enter your name"
//               required
//             />
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="email">Email*</label>
//             <input
//               type="email"
//               id="email"
//               name="email" 
//               className="popupform-input"
//               placeholder="Enter your email address"
//               required
//             />
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="phonenumber">Phone Number*</label>
//             <input
//               type="text"
//               id="phonenumber"
//               name="phonenumber" 
//               className="popupform-input"
//               placeholder="Mobile number"
//               required
//             />
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="course">Preferred Course*</label>
//             <select id="course" name="course" className="popupform-input" required>
//               <option value="">Course you are interested in?</option>
//               <option value="c-programming">C Programming</option>
//               <option value="cpp-programming">C++ Programming</option>
//               <option value="java-programming">Java Programming</option>
//               <option value="react-js">React JS</option>
//               <option value="python-programming">Python Programming</option>
//               <option value="machine-learning">Machine Learning</option>
//               <option value="angular">Angular</option>
//               <option value="node-js">Node.js</option>
//               <option value="data-structures">Data Structures</option>
//               <option value="data-science-analytics">Data Science/Data Analytics</option>
//               <option value="ai">Artificial Intelligence</option>
//               <option value="testing">Manual & Automation Testing</option>
//               <option value="blockchain">Blockchain</option>
//               <option value="cloud-computing-aws">Cloud Computing - AWS</option>
//               <option value="digital-marketing">Digital Marketing</option>
//               <option value="fullstack-development">Full Stack Development</option>
//               <option value="python-fullstack">Python Fullstack</option>
//               <option value="react-fullstack">React Fullstack</option>
//               <option value="java-fullstack">Java Fullstack</option>
//               <option value="aspnet-core-mvc">ASP.Net Core MVC Course</option>
//               <option value="hr-payroll-training">HR & Payroll Training</option>
//               <option value="process-associate">Process Associate (Supply Chain Management)</option>
//               <option value="content-writing">Content Writing</option>
//               <option value="embedded-systems">Embedded Systems</option>
//               <option value="iot">Internet of Things (IoT)</option>
//               <option value="lt-panel-design">LT Panel Design</option>
//               <option value="ht-panel-design">HT Panel Design</option>
//             </select>
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="location">Preferred Mode*</label>
//             <select id="location" name="location" className="popupform-input" required>
//               <option value="">Select a location</option>
//               <option value="offline">Offline</option>
//               <option value="online">Online</option>
//             </select>
//           </div>
//           <div className="popupform-section">
//             <label htmlFor="time">Preferred time to call*</label>
//             <select id="time" name="time" className="popupform-input" required>
//               <option value="">Select a timeslot</option>
//               <option value="9:30am-1pm">9:30am - 1pm</option>
//               <option value="1:30pm-7:20pm">1:30pm - 7:20pm</option>
//             </select>
//           </div>
//         </div>
//       </div>
//     </form>
//   );
// }

// export default PopupForm;


//refactor code

// import React from "react";
// import '../../../CSS/SignUp/SignUp.css';

// // Reusable Input and Select Components
// const FormField = ({ type, id, name, placeholder, options, required }) => (
//   <div className="popupform-section">
//     <label htmlFor={id}>{placeholder}*</label>
//     {type === "select" ? (
//       <select id={id} name={name} className="popupform-input" required={required}>
//         <option value="">{placeholder}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         id={id}
//         name={name}
//         className="popupform-input"
//         placeholder={placeholder}
//         required={required}
//       />
//     )}
//   </div>
// );

// function PopupForm() {
  
//   const courseOptions = [
//     { value: 'c-programming', label: 'C Programming' },
//     { value: 'cpp-programming', label: 'C++ Programming' },
//     { value: 'java-programming', label: 'Java Programming' },
//     { value: 'react-js', label: 'React JS' },
//     { value: 'python-programming', label: 'Python Programming' },
//     { value: 'machine-learning', label: 'Machine Learning' },
//     { value: 'angular', label: 'Angular' },
//     { value: 'node-js', label: 'Node.js' },
//     { value: 'data-structures', label: 'Data Structures' },
//     { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
//     { value: 'ai', label: 'Artificial Intelligence' },
//     { value: 'testing', label: 'Manual & Automation Testing' },
//     { value: 'blockchain', label: 'Blockchain' },
//     { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
//     { value: 'digital-marketing', label: 'Digital Marketing' },
//     { value: 'fullstack-development', label: 'Full Stack Development' },
//     { value: 'python-fullstack', label: 'Python Fullstack' },
//     { value: 'react-fullstack', label: 'React Fullstack' },
//     { value: 'java-fullstack', label: 'Java Fullstack' },
//     { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
//     { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
//     { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
//     { value: 'content-writing', label: 'Content Writing' },
//     { value: 'embedded-systems', label: 'Embedded Systems' },
//     { value: 'iot', label: 'Internet of Things (IoT)' },
//     { value: 'lt-panel-design', label: 'LT Panel Design' },
//     { value: 'ht-panel-design', label: 'HT Panel Design' },
//   ];

//   const locationOptions = [
//     { value: 'offline', label: 'Offline' },
//     { value: 'online', label: 'Online' },
//   ];

//   const timeOptions = [
//     { value: '9:30am-1pm', label: '9:30am - 1pm' },
//     { value: '1:30pm-7:20pm', label: '1:30pm - 7:20pm' },
//   ];

//   return (
//     <form
//       id="popupForm"
//       className="Popupform"
//       method="POST"
//       action="https://formspree.io/f/xldelbjj"
//     >
//       <h1 className="popupform-heading">Sign Up and Start Your Journey</h1>
//       <div className="popupform-container">
//         <div className="popupform-wrapper">
//           <FormField type="text" id="name" name="user_name" placeholder="Enter your name" required />
//           <FormField type="email" id="email" name="User_email" placeholder="Enter your email address" required />
//           <FormField type="text" id="phonenumber" name="user_phonenumber" placeholder="Mobile number" required />
//           <FormField type="select" id="course" name="user_course" placeholder="Course you are interested in?" options={courseOptions} required />
//           <FormField type="select" id="location" name="user_location" placeholder="Select a location" options={locationOptions} required />
//           <FormField type="select" id="time" name="user_available_time" placeholder="Select a timeslot" options={timeOptions} required />
//         </div>
//       </div>
//     </form>
//   );
// }

// export default PopupForm;


// with timeoptions

// import React, { useState } from "react";
// import '../../../CSS/SignUp/SignUp.css';

// // Reusable Input and Select Components
// const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
//   <div className="popupform-section">
//     <label 
//     htmlFor={id} 
//     className="popupcss-label"
//     >{placeholder}*</label>
//     {type === "select" ? (
//       <select
//         id={id}
//         name={name}
//         className={`popupform-input ${error ? 'error' : ''}`}
//         required={required}
//         value={value}
//         onChange={onChange}
//       >
//         <option value="">{placeholder}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         id={id}
//         name={name}
//         className={`popupform-input ${error ? 'error' : ''}`}
//         placeholder={placeholder}
//         required={required}
//         value={value}
//         onChange={onChange}
//       />
//     )}
//     {error && <span className="error-message">{error}</span>}
//   </div>
// );

// function PopupForm() {
//   const [formData, setFormData] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//     user_course: "",
//     user_location: "",
//     // user_available_time: "",
//   });

//   const [errors, setErrors] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     // Validation logic
//     let error = "";
//     if (name === "user_name") {
//       const nameRegex = /^[a-zA-Z\s]+$/;
//       error = nameRegex.test(value) ? "" : "Name must contain only letters.";
//     } else if (name === "user_email") {
//       const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
//       error = emailRegex.test(value) ? "" : "Enter a valid email address.";
//     } else if (name === "user_phonenumber") {
//       const phoneRegex = /^\d{10}$/;
//       error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
//     }
//     setErrors((prev) => ({ ...prev, [name]: error }));
//   };


//   const courseOptions = [
//     { value: 'c-programming', label: 'C Programming' },
//     { value: 'cpp-programming', label: 'C++ Programming' },
//     { value: 'java-programming', label: 'Java Programming' },
//     { value: 'react-js', label: 'React JS' },
//     { value: 'python-programming', label: 'Python Programming' },
//     { value: 'machine-learning', label: 'Machine Learning' },
//     { value: 'angular', label: 'Angular' },
//     { value: 'node-js', label: 'Node.js' },
//     { value: 'data-structures', label: 'Data Structures' },
//     { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
//     { value: 'ai', label: 'Artificial Intelligence' },
//     { value: 'testing', label: 'Manual & Automation Testing' },
//     { value: 'blockchain', label: 'Blockchain' },
//     { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
//     { value: 'digital-marketing', label: 'Digital Marketing' },
//     { value: 'fullstack-development', label: 'Full Stack Development' },
//     { value: 'python-fullstack', label: 'Python Fullstack' },
//     { value: 'react-fullstack', label: 'React Fullstack' },
//     { value: 'java-fullstack', label: 'Java Fullstack' },
//     { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
//     { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
//     { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
//     { value: 'content-writing', label: 'Content Writing' },
//     { value: 'embedded-systems', label: 'Embedded Systems' },
//     { value: 'iot', label: 'Internet of Things (IoT)' },
//     { value: 'lt-panel-design', label: 'LT Panel Design' },
//     { value: 'ht-panel-design', label: 'HT Panel Design' },
//   ];

//   const locationOptions = [
//     { value: 'offline', label: 'Offline' },
//     { value: 'online', label: 'Online' },
//   ];

//   // const timeOptions = [
//   //   { value: '9:30am-1pm', label: '9:30am - 1pm' },
//   //   { value: '1:30pm-7:20pm', label: '1:30pm - 7:20pm' },
//   // ];

//   return (
//     <form
//       id="popupForm"
//       className="Popupform"
//       method="POST"
//       action="https://formspree.io/f/xanyblve"
//     >
//       <h1 className="popupform-heading">Sign Up and Start Your Journey</h1>
//       <div className="popupform-container">
//         <div className="popupform-wrapper">
//           <FormField
//             type="text"
//             id="name"
//             name="user_name"
//             placeholder="Enter your name"
//             required
//             value={formData.user_name}
//             onChange={handleChange}
//             error={errors.user_name}
//           />
//           <FormField
//             type="email"
//             id="email"
//             name="user_email"
//             placeholder="Enter your email address"
//             required
//             value={formData.user_email}
//             onChange={handleChange}
//             error={errors.user_email}
//           />
//           <FormField
//             type="text"
//             id="phonenumber"
//             name="user_phonenumber"
//             placeholder="Mobile number"
//             required
//             value={formData.user_phonenumber}
//             onChange={handleChange}
//             error={errors.user_phonenumber}
//           />
//           <FormField
//             type="select"
//             id="course"
//             name="user_course"
//             placeholder="Course you are interested in?"
//             options={courseOptions}
//             required
//             value={formData.user_course}
//             onChange={handleChange}
//           />
//           <FormField
//             type="select"
//             id="location"
//             name="user_location"
//             placeholder="Select a location"
//             options={locationOptions}
//             required
//             value={formData.user_location}
//             onChange={handleChange}
//           />
//           {/* <FormField
//             type="select"
//             id="time"
//             name="user_available_time"
//             placeholder="Select a timeslot"
//             options={timeOptions}
//             required
//             value={formData.user_available_time}
//             onChange={handleChange}
//           /> */}
//         </div>
//       </div>
//     </form>
//   );
// }

// export default PopupForm;


import React, { useState } from "react";
import '../../../CSS/SignUp/SignUp.css';

// Reusable Input and Select Components
const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
  <div className="popupform-section">
    <label 
    htmlFor={id} 
    className="popupcss-label"
    >{placeholder}*</label>
    {type === "select" ? (
      <select
        id={id}
        name={name}
        className={`popupform-input ${error ? 'error' : ''}`}
        required={required}
        value={value}
        onChange={onChange}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <input
        type={type}
        id={id}
        name={name}
        className={`popupform-input ${error ? 'error' : ''}`}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />
    )}
    {error && <span className="error-message">{error}</span>}
  </div>
);

function PopupForm() {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phonenumber: "",
    user_course: "",
    user_location: "",
  });

  const [errors, setErrors] = useState({
    user_name: "",
    user_email: "",
    user_phonenumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validation logic
    let error = "";
    if (name === "user_name") {
      const nameRegex = /^[a-zA-Z\s]+$/;
      error = nameRegex.test(value) ? "" : "Name must contain only letters.";
    } else if (name === "user_email") {
      const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      error = emailRegex.test(value) ? "" : "Enter a valid email address.";
    } else if (name === "user_phonenumber") {
      const phoneRegex = /^\d{10}$/;
      error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const courseOptions = [
    { value: 'c-programming', label: 'C Programming' },
    { value: 'cpp-programming', label: 'C++ Programming' },
    { value: 'java-programming', label: 'Java Programming' },
    { value: 'react-js', label: 'React JS' },
    { value: 'python-programming', label: 'Python Programming' },
    { value: 'machine-learning', label: 'Machine Learning' },
    { value: 'angular', label: 'Angular' },
    { value: 'node-js', label: 'Node.js' },
    { value: 'data-structures', label: 'Data Structures' },
    { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
    { value: 'ai', label: 'Artificial Intelligence' },
    { value: 'testing', label: 'Manual & Automation Testing' },
    { value: 'blockchain', label: 'Blockchain' },
    { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
    { value: 'digital-marketing', label: 'Digital Marketing' },
    { value: 'fullstack-development', label: 'Full Stack Development' },
    { value: 'python-fullstack', label: 'Python Fullstack' },
    { value: 'react-fullstack', label: 'React Fullstack' },
    { value: 'java-fullstack', label: 'Java Fullstack' },
    { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
    { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
    { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
    { value: 'content-writing', label: 'Content Writing' },
    { value: 'embedded-systems', label: 'Embedded Systems' },
    { value: 'iot', label: 'Internet of Things (IoT)' },
    { value: 'lt-panel-design', label: 'LT Panel Design' },
    { value: 'ht-panel-design', label: 'HT Panel Design' },
  ];

  const locationOptions = [
    { value: 'offline', label: 'Offline' },
    { value: 'online', label: 'Online' },
  ];

  return (
    <form
      id="popupForm"
      className="Popupform"
      method="POST"
      action="https://formspree.io/f/xanyblve"
    >
      <h1 className="popupform-heading">Sign Up and Start Your Journey</h1>
      <div className="popupform-container">
        <div className="popupform-wrapper">
          <FormField
            type="text"
            id="name"
            name="user_name"
            placeholder="Enter your name"
            required
            value={formData.user_name}
            onChange={handleChange}
            error={errors.user_name}
          />
          <FormField
            type="email"
            id="email"
            name="user_email"
            placeholder="Enter your email address"
            required
            value={formData.user_email}
            onChange={handleChange}
            error={errors.user_email}
          />
          <FormField
            type="text"
            id="phonenumber"
            name="user_phonenumber"
            placeholder="Mobile number"
            required
            value={formData.user_phonenumber}
            onChange={handleChange}
            error={errors.user_phonenumber}
          />
          <FormField
            type="select"
            id="course"
            name="user_course"
            placeholder="Course you are interested in?"
            options={courseOptions}
            required
            value={formData.user_course}
            onChange={handleChange}
          />
          <FormField
            type="select"
            id="location"
            name="user_location"
            placeholder="Select a mode of study"
            options={locationOptions}
            required
            value={formData.user_location}
            onChange={handleChange}
          />
        </div>
      </div>
    </form>
  );
}

export default PopupForm;
