import Image1 from '../../../Images/CoursesImages/IT/ITcourse/C Programming.png';
import Image2 from '../../../Images/CoursesImages/IT/ITcourse/C++.png';
import Image3 from '../../../Images/CoursesImages/IT/javai.png';
import Image4 from '../../../Images/CoursesImages/IT/ITcourse/ReactJS.png';
import Image5 from '../../../Images/CoursesImages/IT/ITcourse/Python.png';
import Image6 from '../../../Images/CoursesImages/IT/ITcourse/Machine Learning.png';
import Image7 from '../../../Images/CoursesImages/IT/ITcourse/Angular.png';
import Image8 from '../../../Images/CoursesImages/IT/ITcourse/Node JS.png';
import Image9 from '../../../Images/CoursesImages/IT/ITcourse/Data Structure.png';
import Image10 from '../../../Images/CoursesImages/IT/ITcourse/Data science and Analytics.png';
import Image11 from '../../../Images/CoursesImages/IT/ITcourse/Artificial Intelligence.png';
import Image12 from '../../../Images/CoursesImages/IT/ITcourse/14.png';
import Image13 from "../../../Images/CoursesImages/IT/ITcourse/Block Chain.png";
import Image14 from "../../../Images/CoursesImages/IT/ITcourse/Cloud Computing.png";
import Image15 from '../../../Images/CoursesImages/IT/ITcourse/Digital marketing.png';
import Image16 from '../../../Images/CoursesImages/IT/FullStack.png';
import Image17 from '../../../Images/CoursesImages/IT/ITcourse/python Fullstack.png';
import Image18 from '../../../Images/CoursesImages/IT/ITcourse/React fullstack.png';
import Image19 from '../../../Images/CoursesImages/IT/ITcourse/Java fullstack.png';
import Image20 from '../../../Images/CoursesImages/IT/ITcourse/Asp.net Core MVC course.png';
import Image21 from '../../../Images/CoursesImages/IT/ITcourse/Full Stack Development Net.png';





const ITDetails=[{
    image:Image1,
    heading:"C Programming",
    object:"Unlock the power of C programming with our comprehensive course. Explore the fundamentals and gain practical insights to master the language.",
    courseModules:[{
        id:1,
        title:"Introduction to C"
    },{
        id:2,
        title:"Source Character Set (SCS)",
    },{
        id:3,
        title:"Execution Character Set (ECS)",
    },
{
    id:4,
    title:"Character Set in C"  ,
    description:[{
        subTitle:"Alphabetic Characters (A-Z, a-z)"
    },{
        subTitle:"Digits (0-9)"
    },{
        subTitle:"Special Characters"
    },{
        subTitle:"Whitespace Characters"
    },{
        subTitle:"Control Characters"
    },{
        subTitle:"Escape Sequences"
    },{
        subTitle:"Extended Characters"
    }],

    
},{
    id:5,
    title:"C Variables"
},{
    id:6,
    title:"C Data Types"
},{
    id:7,
    title:"C Constants"
},{
    id:8,
    title:"C Operators"
},
{
    id:9,
    title:"C Booleans"
},{
    id:10,
    title:"C Control Statements"
},{
    id:11,
    title:"C Arrays"
},{
    id:12,
    title:"C Strings"
},{
    id:13,
    title:"C Functions"
},{
    id:14,
    title:"C Storage Classes"
},{
    id:15,
    title:"Preprocessor Directives"
},
{
    id:16,
    title:"Structures, Unions, Enumerations and Typedef"
},{
    id:17,
    title:"Command Line Arguments"
},{
    id:18,
    title:"Files"
},{
    id:19,
    title:"Graphics"
}
],
details:[{
    fee:"₹25,000 + GST",
    duration:"40 Days Training + 10 Days Project"
}],
enrollNow:"Ready to enhance your skills in C programming? Enroll now and kickstart your journey with us!"

},{
    image:Image2,
    heading:"C++ Programming",
    object:"Unlock the potential of C++ programming with our comprehensive course. Whether you're a beginner or seeking to refine your skills, this program covers key concepts to make you proficient in C++.",
    courseModules:[{
     id:1,
     title:"C++ Training Overview",
     description:[{
            subTitle:"1.1 C++ Training Course Objective"
     },{
        subTitle:"1.2 C++ Training Course Duration"
     },]
    },{
        id:2,
        title:" C++ Training Course Duration",
        description:[{
            subTitle:"2.1. Basics"
        },{
            subTitle:"2.2 Functions"
        },{
            subTitle:"2.3 Classes and Objects"
        },{
            subTitle:"2.4 Constructors & Destructors"
        },{
            subTitle:"2.5 Operator Overloading"
        },{
            subTitle:"2.6 Inheritance"
        },{
            subTitle:"2.7 Polymorphism and Virtual Functions"
        },{
            subTitle:"2.8 Templates"
        },{
            subTitle:"2.9 Exception Handling"
        },{
            subTitle:"2.10 Files"
        },{
            subTitle:"2.11 I-O Streams"
        },{
            subTitle:"2.12 Standard Template Libraries"
        },{
            subTitle:"2.13 Understanding Namespacing and Exceptions"
        },{
            subTitle:"2.14 Understanding Macros"
        },{
            subTitle:"2.15 Increment and Decrement Operator"
        },{
        subTitle:"2.16 Introduction to String Class, Derived Class, and Abstract Class"},{
            subTitle:"2.17 Dereferencing"
        }
    ]
    }],
    
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to elevate your C++ programming skills? Enroll now and embark on a journey to master one of the most versatile programming languages!"
},{
    image:Image3,
    heading:"Java Programming ",
    object:"Master Java programming with our comprehensive course. Whether you're a beginner or looking to enhance your skills, this program covers key concepts to make you proficient in Java.",
    courseModules:[{
        id:1,
        title:"Introduction to Java"
    },{
        id:2,
        title:"Java Variables"
    },{
        id:3,
        title:"Java Data Types"
    },{
        id:4,
        title:"Java Type Casting"
    },{
        id:5,
        title:"Java Operators"
    },{
        id:6,
        title:"Java Strings"
    },{
        id:7,
        title:"Java Math Java Booleans"
    },{
        id:8,
        title:"C Control Statement"
    },{
        id:9,
        title:"Java Arrays"
    },{
        id:10,
        title:"Java Classes and Objects"
    },{
        id:11,
        title:"Java Constructors"
    },{
        id:12,
        title:"Java Encapsulation"
    },{
        id:13,
        title:"Java Packages & API"
    },{
        id:14,
        title:"Java Inheritance"
    },{
        id:15,
        title:"Java Polymorphism"
    },{
        id:16,
        title:"Java Abstraction"
    },{
        id:17,
        title:"Java Interface"
    },{
        id:18,
        title:"Java Wrapper Classes"
    },{
        id:19,
        title:"Java Exceptions - Try...Catch"
    },{
        id:20,
        title:"Java Regular Expressions"
    },{
        id:21,
        title:"Java Threads"
    },
{
    id:22,
    title:"Java File Handling"
}],
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to level up your React JS skills? Enroll now and dive into the world of building dynamic and interactive user interfaces!"
},{
    
    
    image:Image4,
    heading:"React JS",
    object:"Explore React JS with our comprehensive course. Perfect for beginners and those looking to enhance their skills, this program covers key concepts to make you proficient in React.",
    courseModules:[{
        id:1,
        title:"HTML + CSS"
    },{
        id:2,
        title:"JavaScript Fundamentals",
    },{
        id:3,
        title:"React JS Rendering Elements"
    },{
        id:4,
        title:"Components"
    },{
        id:5,
        title:"Props and States"
    },{
        id:6,
        title:"Lifecycle of Components"
    },{
        id:7,
        title:"Hooks"
    },{
        id:8,
        title:"Router"
    },{
        id:9,
        title:"ReactDom"
    },{
        id:10,
        title:"Redux"
    }
], 
details:[{
    fee:"₹25,000 + GST",
    duration:"40 Days Training + 10 Days Project"
}],
enrollNow:"Ready to level up your React JS skills? Enroll now and dive into the world of building dynamic and interactive user interfaces!"


},{
    //5th map method modify!
    image:Image5,
    heading:"Python Programming",
    object:"Explore React JS with our comprehensive course. Perfect for beginners and those looking to enhance their skills, this program covers key concepts to make you proficient in React.",
    courseModules:[{
        id:1,
        title:"	Python Training Overview",
        description:[
            {
                subTitle:"What are the Python Course Prerequisites"
            },{
                subTitle:"Objectives of the Course",
            },{
                subTitle:"Who should do the course",
            },{
               subTitle:"Python Training Course Duration"
            }
        ]
    },{
        id:2,
        title:" Python Course Content",
        description:[
            {
                subTitle:"Core Python",
                Topics:[
                    {
                        subTopic:"	Introduction to Languages",
                    },{
                        subTopic:"Introduction to Python",
                    },{
                        subTopic:"	Python Software’s",
                    },{
                        subTopic:"Python Language Fundamentals",
                    },{
                        subTopic:"Different Modes of Python"
                    },{
                        subTopic:"Python Variables"
                    },{
                        subTopic:"Operators"
                    },{
                        subTopic:"Input & Output Operators"
                    },{
                        subTopic:"	Data Structures or Collections"
                    },{
                        subTopic:"	List Collection"
                    },{
                        subTopic:"Tuple Collection"
                    },{
                        subTopic:"Set Collection"
                    },{
                        subTopic:"Dictionary Collection"
                    },{
                        subTopic:"Functions"
                    }]
            },{
                subTitle:"Advanced Python",
                Topics:[{
                    subTopic:"Python Modules"
                },{
                    subTopic:"Packages"
                },{
                    subTopic:"OOPs"
                }] 
            },{
                subTitle:"	PANDAS"
            },{
                subTitle:"NUMPY"
            }],
             }],
 details:[{
    fee:"₹25,000 + GST",
    duration:"40 Days Training + 10 Days Project"
}],
enrollNow:"Ready to enhance your Python programming skills? Enroll now and embark on a journey to become a Python pro!"
},{
    image:Image6,
    heading:"Machine Learning",
    object:"Unlock the potential of Machine Learning with our comprehensive course. Designed for beginners and those looking to deepen their understanding, this program covers key concepts to make you proficient in Machine Learning.",
    courseModules:[{
        id:1,
        title:" Basics of Machine Learning"
    },{
        id:2,
        title:" Artificial Learning (AI)"
    },{
        id:3,
        title:' Machine Learning (ML)'
    },{
        id:4,
        title:" Types and Algorithms of Machine Learning"
    },{
        id:5,
        title:" Types and Algorithms of Machine Learning",
        description:[
            {
                subTitle:"Supervised Learning"
            },{
                subTitle:"Semi-Supervised Learning",
            },{
                subTitle:"Unsupervised Learning",
            },{
                subTitle:"Reinforcement Learning"
            }
        ]
    },{
        id:6,
        title:" Neural Network or Artificial Neural Network (ANN)",
    },{
        id:7,
        title:" Machine Learning Use Cases"
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to delve into the world of Machine Learning? Enroll now and kickstart your journey towards mastering this transformative field!"
},{
    image:Image7,
    heading:" Angular ",
    object:"Explore Angular with our comprehensive course. Whether you're a beginner or looking to enhance your skills, this program covers key concepts to make you proficient in Angular.",
    courseModules:[{
        id:1,
        title:" AngularJS Training Overview",
        description:[{
            subTitle:"AngularJS Training Objectives"
        },{
            subTitle:"Who should do the course"
        },{
            subTitle:"Prerequisites for AngularJS Course"
        }
    ]
    },{
        id:2,
        title:" Angularjs Course Content",
        description:[{
            subTitle:"AngularJS Introduction"
        },{
            subTitle:"Execution flow"
        },{
            subTitle:"Controllers"
        },{
            subTitle:"Bootstrapping Angular APPS"
        },{
            subTitle:"Data Binding"
        },{
            subTitle:"Understanding AngularJS Architecture"
        },{
            subTitle:"Dependency Injection"
        },{
            subTitle:"Expressions"
        },{
            subTitle:"Directives"
        },{
            subTitle:"Filters"
        },{
            subTitle:"Events"
        },{
            subTitle:"Modules"
        },{
            subTitle:"AngularJS XMLHttpRequest (AJAX)"
        },{
            subTitle:"AngularJS Forms & Input Validation AngularJS Service Types"
        },{
            subTitle:"Single Page Applications"
        },{
            subTitle:"REST API Calls"
        },{
            subTitle:"Animations"
        },{
            subTitle:"Angular with UI Frameworks"
        },{
            subTitle:"Behavior Driven Development"
        }]     
    }],
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to elevate your Angular skills? Enroll now and dive into the world of building dynamic and responsive web applications with Angular!"
},{
    image:Image8,
    heading:"Node.js ",
    object:"Master Node.js with our comprehensive course. Whether you're a beginner or looking to sharpen your skills, this program covers key concepts to make you proficient in Node.js.",
    courseModules:[{
        id:1,
        title:" NodeJS Course Overview",
        description:[{
            subTitle:"Objectives of the Course"
        },{
            subTitle:" Pre-Requisites of the Course"
        },{
            subTitle:"Who should do the course"
        }]
    },{
        id:2,
        title:" NodeJS Training Content",
        description:[{
            subTitle:"Introduction to Node.js"
        },{
            subTitle:"Node.js Platform Setup"
        },{
            subTitle:"Modules and npm"
        },{
            subTitle:"Creating Web Server"
        },{
            subTitle:"File System"
        },{
            subTitle:"Debugging Node JS Application"
        },{
            subTitle:"The Callback Pattern"
        },{
            subTitle:"Events"
        },{
            subTitle:"Error Handling"
        },{
            subTitle:"Express JS"
        },{
            subTitle:"Buffers"
        },{
            subTitle:"Streams"
        },{
            subTitle:"Socket.io"
        },{
            subTitle:"Connecting to Databases"
        }]
    }],
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to advance your Node.js skills? Enroll now and embark on a journey to become a skilled developer using Node.js!"
},{
    image:Image9,
    heading:"Data Structures ",
    object:"Explore the world of Data Structures with our comprehensive course. Perfect for beginners and those looking to deepen their understanding, this program covers key concepts to make you proficient in designing efficient data structures.",
    courseModules:[{
        id:1,
        title:" Algorithm Tutorial"
    } ,{
        id:2,
        title:" Searching Algorithms"
    },{
        id:3,
        title:" Sorting Algorithms"
    },{
        id:4,
        title:" Recursion Algorithms"
    },{
        id:5,
        title:" Backtracking Algorithms"
    },{
        id:6,
        title:" Greedy Algorithms"
    },{
        id:7,
        title:" Dynamic Programming"
    },{
        id:8,
        title:" Pattern Searching"
    },{
        id:9,
        title:" Divide and Conquer"
    },{
        id:10,
        title:" Mathematical Algorithms"
    },{
        id:11,
        title:" Geometric Algorithms"
    },{
        id:12,
        title:"Bitwise Algorithms"
    },{
        id:13,
        title:" Randomized Algorithms"
    },{
        id:14,
        title:" Branch and Bound Algorithm"
    }],
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to master Data Structures? Enroll now and equip yourself with the skills to organize and manipulate data effectively!"
},{
    image:Image10,
    heading:"Data Science/Data Analytics ",
    object:"Unlock the world of Data Science and Data Analytics with our comprehensive course. Whether you're a beginner or seeking to enhance your skills, this program covers key concepts to make you proficient in extracting insights from data",
    courseModules:[{
        id:1,
        title:" Data Science Training Overview",
        description:[{
            subTitle:"Objectives of the Course"
        },{
            subTitle:"Pre-Requisites  of the Course"
        },{
            subTitle:"Course Duration"
        }],

    },{
        id:2,
        title:" Data Science Course Content",
        description:[{
            subTitle:"Introduction to Data Science"
        },{
            subTitle:"Data"
        },{
            subTitle:"Big Data"
        },{
            subTitle:"Data Science Deep Dive"
        },{
            subTitle:"Intro to R Programming"
        },{
            subTitle:"R Programming Concepts"
        },{
            subTitle:"Data Manipulation in R"
        },{
            subTitle:"Data Import Techniques in R"
        },{
            subTitle:"Exploratory Data Analysis (EDA) using R"
        },{
            subTitle:"Data Visualization in R"
        },{
            subTitle:"HADOOP",
            Topics:[{
                subTopic:"a) Big Data and Hadoop Introduction"
            },{
                subTopic:"b) Understand Hadoop Cluster Architecture"
            },{
                subTopic:"c) Map Reduce Concepts"
            },{
                subTopic:"d) Advanced Map Reduce Concepts"
            }]
        },{
            subTitle:"Hadoop 2.0 and YARN"
        },{
            subTitle:"	PIG"
        },{
            subTitle:"HIVE",
            Topic:[{
                subTopic:"Module-9"
            }]
        },{
            subTitle:"HBASE",
            Topic:[{
                subTopic:"Module-11"
            }]
        },{
            subTitle:"SQOOP"
        },{
            subTitle:"Flume and Oozie"
    },{
        subTitle:"Projects"
    },{
        subTitle:"Project in Healthcare Domain"
    },{
        subTitle:"Project in Finance/Banking Domain"
    },{
        subTitle:"Spark",
        Topics:[
            {
            subTopic:"a) Apache Spark"
        },
            {
            subTopic:"b) Introduction to Scala"
        },
            {
            subTopic:"c) Spark Core Architecture"
        },
            {
            subTopic:"d) Spark Internals"
        },
            {
            subTopic:"e) Spark Streaming"
        }]
    },{
        subTitle:"Statistics + Machine Learning",
     },{
        subTitle:"Python",
        Topics:[{
            subTopic:" Getting Started with Python"
        },{
            subTopic:"Sequences and File Operations"
        }]
     },{
        subTitle:"	Deep Dive – Functions Sorting Errors and Exception Handling"
     },{
        subTitle:"	Regular Expressionist’s Packages and Object – Oriented Programming in Python"
     },{
        subTitle:"Debugging, Databases and Project Skeletons"
     },{
        subTitle:"Machine Learning Using Python"
     },{
        subTitle:"Supervised and Unsupervised learning"
     },{
        subTitle:"Algorithm"
     },{
        subTitle:"	Application Example"
     },{
        subTitle:"Scikit and Introduction to Hadoop"
     },{
        subTitle:"Hadoop and Python"
     },{
        subTitle:"Python Project Work"
     }],     
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to explore the realm of Data Science and Analytics? Enroll now and start your journey toward becoming a data-driven professional!"
},{
    image:Image11,
    heading:"Artificial Intelligence ",
    object:"Explore the cutting-edge field of Artificial Intelligence with our comprehensive course. Designed for both beginners and those looking to deepen their knowledge, this program covers key concepts to make you proficient in AI technologies.",
    courseModules:[{
        id:1,
        title:" About Artificial Intelligence Training"
    },{
        id:2,
        title:"Introduction to Data Science Deep Learning & Artificial Intelligence"
    },{
        id:3,
        title:" Python"
    },{
        id:4,
        title:" Statistics"
    },{
        id:5,
        title:" Machine Learning, Deep Learning & AI using Python"
    },{
        id:6,
        title:" Deep Learning & AI using Python"
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to unlock the potential of Artificial Intelligence? Enroll now and embark on a journey to understand and apply AI techniques in various domains!"
},{
    image:Image12,
    heading:"Manual & Automation Testing ",
    object:"Master the art of software testing with our comprehensive Manual & Automation Testing course. Whether you're a testing enthusiast or a professional looking to enhance your skills, this program covers key concepts in both manual and automated testing methodologies.",
    courseModules:[{
        id:1,
        title:" Introduction to testing"
    },{
        id:2,
        title:" Verification vs validation"
    },{
        id:3,
        title:" Types of Applications"
    },{
        id:4,
        title:" Probabilities of getting an error in an application"
    },{
        id:5,
        title:" SDLC – Software Development Life Cycle",
        description:[{
            subTitle:"Waterfall"
        },{
            subTitle:"Prototype"
        },{
            subTitle:" Spiral"
        },{
            subTitle:" Incremental(Agile methodology and Scrum Framework)"
        },{
            subTitle:"V-Model"
        }],
    },{
        id:6,
        title:" Advantages and Disadvantages of each software development life cycle"
    },{
        id:7,
        title:" Principles of Testing"
    },{
        id:8,
        title:" Principles of Testing"
    },{
        id:9,
        title:"  Difference between Test case, Use case and Scenario’s."
    },{
        id:10,
        title:" How to prepare test plan and strategy"
    },{
        id:11,
        title:" How to Prepare test case template?"
    },{
        id:12,
        title:" Difference between Error, bug, defect and failure"
    },{
        id:13,
        title:" Test Case Design Technique"
    },{
        id:14,
        title:" Bug Life cycle"
    },{
        id:15,
        title:" How to Prepare Bug template?"
    },{
        id:16,
        title:" Bug Tracking tool"
    },{
        id:17,
        title:" Types of Testing"
    },{
        id:18,
        title:" Difference between static and Dynamic testing"
    },{
        id:19,
        title:" Difference between Functional and Non-functional testing"
    },{
        id:20,
        title:" Black box testing and its types"
    },{
        id:21,
        title:" White box testing and its types"
    },{
        id:22,
        title:" System Integration Testing vs User Acceptance Testing"
    },{
        id:23,
        title:" Entry Criteria and Exit Criteria"
    },{
        id:24,
        title:" Test Environment and Test data preparation"
    },{
        id:25,
        title:" Flow graph notations"
    },{
        id:26,
        title:" Integration testing"
    },{
        id:27,
        title:" Requirement traceability matrix"
    }],
    heading1:"1. Automation Testing - Selenium",
    AdditionalCourse:[{
        id:1,
        title:" Introduction Selenium IDE",
        description:[{
            subTitle:"Installing Selenium IDE"
        },{
            subTitle:" How to use Locators in Selenium IDE"
        },{
            subTitle:" How to enhance a script using Selenium IDE"
        },{
            subTitle:" Creating and Running Tests"
        },{
            subTitle:"Creating and Running Test Suite"
        }]
    },{
        id:2,
        title:" WebDriver Introduction",
        description:[{
            subTitle:"Introduction to WebDriver & Comparison with Selenium RC"
        },{
            subTitle:"Installing Selenium WebDriver"
        },{
            subTitle:" Architecture of selenium Webdriver"
        },{
            subTitle:"Creating your First Script in Webdriver"
        }]
    },{
        id:3,
        title:" Operations on various Elements",
        description:[{
            subTitle:" Browser"
        },{
            subTitle:" TextBox"
        },{
            subTitle:"ListBox"
        },{
            subTitle:"Links"
        },{
            subTitle:"Check Box"
        },{
            subTitle:"Radio Button"
        },{
            subTitle:"HTML Tables"
        },{
            subTitle:"Calendars"
        }]
    },{
        id:4,
        title:"  Keyboard and Mouse Events",
        description:[{
            subTitle:"Action Class"
        },{
            subTitle:" KeyBoard Events"
        },{
            subTitle:" Drag & Drop Actions"
        },{
            subTitle:"Mouse Hover Action"
        },{
            subTitle:"RightClick, Double Click"
        },{
            subTitle:" Tool Tip"
        }],
    },{
        id:5,
        title:"Handling Alerts",
        description:[{
            subTitle:"accept()"
        },{
            subTitle:" dismiss()"
        },{
            subTitle:"getText()"
        },{
            subTitle:"sendKeys()"
        }]
    },{
        id:6,
        title:" Handling Frames"
    },{
        id:7,
        title:" Creating Customize XPath/CSS Selectors"
    },{
        id:8,
        title:" Synchronization"
    },{
        id:9,
        title:" What is TestNG?"
    },{
        id:10, 
        title:" Automation Test Frameworks"
    },{
        id:11,
        title:" Page Object Model (POM) & Page Factory in Selenium"
    },{
        id:12,
        title:" Database Testing using Selenium"
    },{
        id:13,
        title:" Selenium GRID"
    },{
        id:14,
        title:" LOG4J"
    }],
    details:[{
        fee:"₹20,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to excel in Manual & Automation Testing? Enroll now and gain expertise in both manual and automated testing methodologies!"

},{
    image:Image13,
    heading:"Blockchain ",
    object:"Dive into the revolutionary world of Blockchain with our comprehensive course. Perfect for beginners and those looking to enhance their skills, this program covers key concepts to make you proficient in the fundamentals of blockchain technology.",
    courseModules:[{
        id:1,
        title:" BlockChain Training Overview",
        description:[{
            subTitle:"Who can take this Course"
        },{
            subTitle:"Prerequisites for Course"
        }]
    },{
        id:2,
        title:"BlockChain Course Content",
        description:[{
            subTitle:"Introduction to Blockchain"
        },
        {
            subTitle:"Key Concepts of the Blockchain"
        },
        {
            subTitle:"Introduction to Ethereum"
        },{
            subTitle:"Learn Solidity"
        },{
            subTitle:"Implement Dapp"
        },{
            subTitle:"Future Scope"
        }]
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to explore the potential of Blockchain? Enroll now and start your journey towards understanding the decentralized future of digital transactions!"
},{
    image:Image14,
    heading:"Cloud Computing - AWS ",
    object:"Unlock the power of Cloud Computing with our comprehensive AWS course. Whether you're a beginner or looking to enhance your skills, this program covers key concepts to make you proficient in utilizing Amazon Web Services.",
    courseModules:[{
        id:1,
        title:" AWS Introduction"
    },{
        id:2,
        title:"Introduction to Cloud Computing"
    },{
        id:3,
        title:"Cloud Computing Deployment Models"
    
    },{
        id:4,
        title:"Cloud Delivery Models"
    
    },{
        id:5,
        title:"Introduction to AWS"
    
    },{
        id:6,
        title:"Amazon Web Services(AWS)"
    
    },{
        id:7,
        title:"Elastic Compute Cloud (EC2) EssentialsEC2 Instances"
    
    },{
        id:8,
        title:"Simple Queue Service (SQS)"
    
    },{
        id:9,
        title:"Simple Email Services (SES)"
    
    },{
        id:10,
        title:"Elastic Beanstalk"
    
    },{
        id:11,
        title:"Elastic Block Store (EBS)s"
    
    },{
        id:12,
        title:"Elastic Load Balancer (ELB)"
    
    },{
        id:13,
        title:" Auto Scaling"
    },{
        id:14,
        title:" Simple Storage Service (S3)"
    },{
        id:15,
        title:" Glacier Storage"
    },{
        id:16,
        title:" Identity and Access Management (IAM)"
    },{
        id:17,
        title:" Virtual Private Cloud (VPC)"
    },{
        id:18,
        title:" Cloud watch"
    },{
        id:19,
        title:"Simple Notification Services (SNS)"
    },{
        id:20,
        title:" Relational Database Service (RDS)"
    },{
        id:21,
        title:"Cloud Formation"
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to harness the capabilities of AWS? Enroll now and dive into the world of cloud computing, building scalable and reliable solutions with Amazon Web Services!"
},{
    image:Image15,
    heading:"Digital Marketing ",
    object:"Explore the dynamic field of Digital Marketing with our comprehensive course. Perfect for beginners and those looking to enhance their skills, this program covers key concepts to make you proficient in the art and science of online marketing.",
    courseModules:[{
        id:1,
        title:" Niche Discovery"
    },{
        id:2,
        title:"Content Writing"
    },{
        id:3,
        title:" Wordpress Management"
    },{
        id:4,
        title:"Search Engine Optimization"
    },{
        id:5,
        title:" Facebook Ads"
    },{
        id:6,
        title:" Search Engine Marketing"
    },{
        id:7,
        title:" Automation"
    },{
        id:8,
        title:" Landing Page"
    },{
        id:9,
        title:" LinkedIn Marketing"
    },{
        id:10,
        title:" Social Media Management"
    },{
        id:11,
        title:" Instagram for Business"
    },{
        id:12,
        title:" Digital Design Mastery"
    }],
    details:[{
        fee:"₹25,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to navigate the digital landscape? Enroll now and embark on a journey to become a skilled digital marketer, driving success in the online world!"
},{
    image:Image16,
    heading:"Full Stack Development ",
    object:"Become a Full Stack Developer with our comprehensive course covering Java, Python, React JS, and Angular. Whether you're a beginner or looking to broaden your skills, this program covers key concepts to make you proficient in both front-end and back-end development. ",
    courseModules:[{
        id:1,
        title:" HTML"
    },{
        id:2,
        title:" CSS"
    },{
        id:3,
        title:" JavaScript",
        description:[{
            subTitle:"React"
        },{
            subTitle:"Angular"
        },{
            subTitle:"Vue"
        }]
    },{
        id:4,
        title:" Choose and learn a language",
        description:[{
            subTitle:"JavaScript"
        },{
            subTitle:"Java"
        },{
            subTitle:"c#"
        },{
            subTitle:"PHP"
        },{
            subTitle:" Python"
        },{
            subTitle:" Ruby"
        },{
            subTitle:"Go"
        },{
            subTitle:" Rust"
        },{
            subTitle:"NodeJs"
        }]
    },{
        id:5,
        title:"DataBases",
        description:[{
            subTitle:"Relational",
            Topics:[
                {
                    subTopic:" PostgreSQL"
                },{
                    subTopic:" MySQL"
                },{
                    subTopic:" MariaDB"
                },{
                    subTopic:" MS SQL"
                },{
                    subTopic:" Oracle"
                }]
        },{
            subTitle:" Non-Relational",
            Topics:[{
                subTopic:" MongoDB"
            },{
                subTopic:" CouchDB"
            },{
                subTopic:" Cassandra"
            },{
                subTopic:" InfluxDB"
            },{
                subTopic:" Firebase"
            }]
        }]
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to master Full Stack Development? Enroll now and embark on a journey to build robust and scalable web applications using a combination of Java, Python, React JS, and Angular!"
},{
    image:Image17,
    heading:"Python Fullstack",
    object:"The Python Full Stack course teaches you how to build websites and applications using Python. You'll learn front-end and back-end development, database management, security, testing, and deployment. With hands-on projects, you'll gain practical skills to create your own full-stack applications.",
    courseModules:[{
        id:1,
        title:"Introduction to Python Programming"
    },{
        id:2,
        title:"Object-Oriented Programming (OOP) with Python"
    },{
        id:3,
        title:"Web Development Basics"
    },{
        id:4,
        title:"Flask Framework for Web Development"
    },{
        id:5,
        title:"Django Framework for Web Development"
    },{
        id:6,
        title:"Relational Database Management Systems (RDBMS) with SQL and SQLite"
    },{
        id:7,
        title:"RESTful APIs Development"
    },{
        id:8,
        title:"Deployment and Continuous Integration/Continuous Deployment (CI/CD)"
    },{
        id:9,
        title:"Project Work"
    },{
        id:10,
        title:"Testing and Debugging"
    },{
        id:11,
        title:"Security Best Practices"
    },{
        id:12,
        title:"Performance Optimization"
    },{
        id:13,
        title:"Asynchronous Programming"
    },{
        id:14,
        title:"Advanced Topics"
    }],
    details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to master Full Stack Development? Enroll now and embark on a journey to build robust and scalable web applications using a combination of Java, Python, React JS, and Angular!"
},{
    image:Image18,
    heading:"React fullstack",
    object:"This extensive course aims to empower learners with the necessary skills to excel as full-stack developers, utilizing React.js for front-end and Node.js/Express.js for back-end development. It caters to individuals at all proficiency levels, providing a structured curriculum encompassing fundamental principles and advanced methodologies crucial for constructing contemporary web applications.",
    courseModules:[{
        id:1,
        title:"Introduction to JavaScript and React Basics",
        description:[{
            subTitle:"Basic JavaScript concepts"
        },{
            subTitle:"Introduction to React.js"
        },{
            subTitle:"Setting up a React development environment"
        }]
    },{
        id:2,
        title:"Front-end Development with React",
        description:[{
            subTitle:"Components and Props"
        },{
            subTitle:"Handling Events"
        },{
            subTitle:"Styling with CSS or CSS-in-JS libraries"
        }]
    },{
        id:3,
        title:"Introduction to Node.js and Express.js",
        description:[{
            subTitle:"Setting up a Node.js environment"
        },{
            subTitle:"Introduction to Express.js framework"
        },{
            subTitle:"Routing and Middleware"
        }]
    },{
        id:4,
        title:"Back-end Development with Node.js and Express.js",
        description:[{
            subTitle:"Creating RESTful APIs"
        },{
            subTitle:"Handling HTTP requests and responses"
        },{
            subTitle:"Database integration (e.g., MongoDB, MySQL, PostgreSQL)"
        }]
    },
{
    id:5,
    title:"Full Stack Development with React and Node.js/Express.js",
    description:[{
        subTitle:"Integrating React front-end with Node.js/Express.js back-end"
    },{
        subTitle:"Consuming APIs in React"
    },{
        subTitle:"Authentication and Authorization (e.g., JWT)"
    }]
},{
    id:6,
    title:"Advanced React Concepts",
    description:[{
        subTitle:"React Hooks"
    },{
        subTitle:"Context API"
    },{
        subTitle:"React Router for navigation"
    }]
},{
    id:7,
    title:"Advanced Node.js/Express.js Topics",
    description:[{
        subTitle:"Authentication middleware (e.g., Passport.js)"
    },{
        subTitle:"Error handling and logging"
    },{
        subTitle:"File uploading and streaming"
    }]
},{
    id:8,
    title:"Database Management and Integration",
    description:[{
        subTitle:"Database design and schema creation"
    },{
        subTitle:"CRUD operations (Create, Read, Update, Delete)"
    },{
        subTitle:"Querying databases from Node.js/Express.js"
    }]
},{
    id:9,
    title:"State Management in React Applications",
    description:[{
        subTitle:"Introduction to state management libraries (e.g., Redux, MobX)"
    },{
        subTitle:"Managing global state with Redux"
    },{
        subTitle:"Asynchronous actions and middleware"
    }]
},{
    id:10,
    title:"Testing React and Node.js Applications",
    description:[{
        subTitle:"Unit testing with Jest and React Testing Library"
    },{
        subTitle:"Testing Node.js/Express.js APIs with Supertest"
    }]
},{
    id:11,
    title:"Deployment and Continuous Integration/Continuous Deployment (CI/CD)",
    description:[{
        subTitle:"Deploying React and Node.js applications to platforms like Heroku, AWS, or Netlify"
    },{
        subTitle:"Setting up CI/CD pipelines with tools like GitHub Actions or Travis CI"
    }]
},{
    id:12,
    title:"Real-world Projects",
    description:[{
        subTitle:"Building full-stack applications from scratch"
    },{
        subTitle:"Integrating third-party APIs"
    },{
        subTitle:"Implementing advanced features and functionalities"
    }]
},{
    id:13,
    title:"Project Deployment and Scaling",
    description:[{
        subTitle:"Strategies for scaling React and Node.js applications"
    },{
        subTitle:"Performance optimization techniques"
    },{
        subTitle:"Monitoring application health and performance"
    }]
},{
    id:14,
    title:"Security Best Practices",
    description:[{
        subTitle:"Securing React and Node.js applications against common vulnerabilities (e.g., XSS, CSRF)"
    },{
        subTitle:"Implementing HTTPS and secure authentication mechanisms"
    }]
},{
    id:15,
    title:"Advanced Topics",
    description:[{
        subTitle:"Server-Side Rendering (SSR) with React and Node.js"
    },{
        subTitle:"GraphQL API implementation"
    },{
        subTitle:"Microservices architecture with React and Node.js"
    }]
}],details:[{
    fee:"₹30,000 + GST",
    duration:"40 Days Training + 10 Days Project"
}],
enrollNow:"Ready to master Full Stack Development? Enroll now and embark on a journey to build robust and scalable web applications using a combination of Java, Python, React JS, and Angular!"
},{
    image:Image19,
    heading:"Java Fullstack course",
    object:"Our program aims to equip you with the skills and knowledge essential for becoming a proficient full-stack developer using Java technologies. Whether you're starting out or seeking to enhance your expertise, our course provides guidance throughout every step of your journey.",
    courseModules:[{
        id:1,
        title:"Introduction to Java",
        },{
            id:2,
            title:"Object-Oriented Programming (OOP) for Java Full Stack"
        },{
            id:3,
            title:"Java Collections Framework for Java Full Stack"
        },{
            id:4,
            title:"Java Database Connectivity (JDBC)"
        },{
            id:5,
            title:"Web Development Basics"
        },{
            id:6,
            title:"Spring Framework for Full Stack"
        },{
            id:7,
            title:"Spring Boot Development for Java Full Stack"
        },{
            id:8,
            title:"Front-end Development with Angular or React for Java Full Stack"
        },{
            id:9,
            title:"Advanced Spring Features for Java Full Stack"
        },{
            id:10,
            title:"Deployment and Continuous Integration/Continuous Deployment (CI/CD) for Full Stack Java Applications"
        },{
            id:11,
            title:"Project Work for Java Full Stack"
        },{
            id:12,
            title:"Testing and Debugging for Java Full Stack"
        },{
            id:13,
            title:"Performance Tuning and Monitoring"
        },{
            id:14,
            title:"Security Best Practices for Java Full Stack"
        },{
            id:15,
            title:"Java Full Stack for Microservices Architecture"
        },{
            id:16,
            title:"Project Deployment and Scaling with Java Full Stack"
        }
    ],details:[{
        fee:"₹30,000 + GST",
        duration:"40 Days Training + 10 Days Project"
    }],
    enrollNow:"Ready to master Full Stack Development? Enroll now and embark on a journey to build robust and scalable web applications using a combination of Java, Python, React JS, and Angular!"

},{
    image: Image20,
    heading: "Full Stack Development Course",
    object: "Get introduced to full stack development and master the skills needed to build robust web applications from front-end to back-end.",
    courseModules: [{
        id: 1,
        title: "Introduction to full stack development"
    }, {
        id: 2,
        title: "Introduction to .Net Core"
    }, {
        id: 3,
        title: "C# Fundamentals",
        submodules: [
            { id: 1, title: "Basic syntax and structure" },
            { id: 2, title: "Variables, data types, and operators" },
            { id: 3, title: "Control structures (loops, conditionals)" },
            { id: 4, title: "Functions and methods" },
            { id: 5, title: "Object-oriented programming concepts" }
        ]
    }, {
        id: 4,
        title: "Building RESTful APIs with ASP.Net Core"
    }, {
        id: 5,
        title: "Entity Framework Core",
        submodules: [
            { id: 1, title: "Introduction to ORM and Entity Framework Core" },
            { id: 2, title: "Setting up Entity Framework Core" },
            { id: 3, title: "Code-first vs Database-first approaches" },
            { id: 4, title: "Migrations" },
            { id: 5, title: "LINQ queries" },
            { id: 6, title: "Working with databases (SQL Server)" }
        ]
    }, {
        id: 6,
        title: "Front-End Development with HTML, CSS, and JavaScript"
    }, {
        id: 7,
        title: "Front-End Framework (Angular)"
    }, {
        id: 8,
        title: "Integrating Front-End with .NET Core Back-End"
    }, {
        id: 9,
        title: "Authentication and Authorization"
    }, {
        id: 10,
        title: "Building and Deploying Applications"
    },
    //  {
    //     id: 11,
    //     title: "Project (10 Days)"
    // }
],
    details: [{
        fee: "₹50,000 + GST",
        duration: " 10 Days Project"
    }],
    enrollNow: "Ready to become a full stack developer? Enroll now and start your journey towards mastering web development!"
},{
    image: Image21,
    heading: "ASP.Net Core MVC Course",
    object: "Master ASP.Net Core MVC with our comprehensive course. Perfect for developers looking to build powerful web applications using Microsoft's popular framework.",
    courseModules: [{
        id: 1,
        title: "C# Fundamentals",
        submodules: [
            { id: 1, title: "Basic syntax and structure" },
            { id: 2, title: "Variables, data types, and operators" },
            { id: 3, title: "Control structures (loops, conditionals)" },
            { id: 4, title: "Functions and methods" },
            { id: 5, title: "Object-oriented programming concepts" }
        ]
    }, {
        id: 2,
        title: "Introduction to ASP.Net Core MVC"
    }, {
        id: 3,
        title: "Core Concepts of MVC"
    }, {
        id: 4,
        title: "Working with Data"
    }, {
        id: 5,
        title: "Advanced Routing and Model Binding"
    }, {
        id: 6,
        title: "Dependency Injection and Services"
    }, {
        id: 7,
        title: "Authentication and Authorization"
    }, {
        id: 8,
        title: "Building and Consuming APIs"
    }, {
        id: 9,
        title: "Frontend Integration",
        submodules: [
            { id: 1, title: "Using CSS and Javascript in ASP.NET Core" },
            { id: 2, title: "Working with Layouts and Partial Views" },
            { id: 3, title: "Using Tag Helpers" },
            { id: 4, title: "Integrating with Frontend Frameworks (eg., Bootstrap, Angular)" },
            { id: 5, title: "Client-side Validation" }
        ]
    }, {
        id: 10,
        title: "Building and Deployment"
    },
    //  {
    //     id: 11,
    //     title: "Project (10 Days)"
    // }
],
    details: [{
        fee: "₹35,000 + GST",
        duration: " 10 Days Project"
    }],
    enrollNow: "Ready to become an ASP.Net Core MVC expert? Enroll now and start your journey towards building sophisticated web applications!"
}


];



export default ITDetails;