import React from 'react'
import logo from '../../../Images/Logo/My Inception Logo.png'
import { Link } from 'react-router-dom'
export default function Logo() {
  return (
    <div className='LogoContainer'>
      <Link to="/"> 
      <img src={logo} alt={"LOGO"} className="Logo"></img>
      </Link>
    </div>
  )
}


