import React from 'react'
import Button from '@mui/material/Button'
import '../../../CSS/JobDescripe/JobDescrip.css'
import {Link} from 'react-router-dom'
// import LayOut from '../NavigationBar/LayOut';


function JobDescrip() {
  return (

   
    <div className="courses">

       <div className='itCourse'>
       <Link to="/it-jobs">
        <Button variant="contained" color="primary"> IT Jobs </Button>
        </Link>
    </div>
    <div className="nonItCourse">
    <Link to="/non-it-jobs">
    <Button variant="contained" color="primary"> Non-IT Jobs </Button>
    </Link>
    </div>
    </div>
   
   
  )
}

export default JobDescrip








// import React from 'react'
// import Button from '@material'

// export default function Button() {
//   return (
//     <div className="courses">
//     <div className='itCourse'>
//         <button>IT</button>
//     </div>
//     <div className="nonIt">
//         <button>Non-IT</button>
//     </div>
//     </div>    
//   )
// }
// import React from 'react';
// import Button from '@mui/material/Button';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1),
//     '&:hover': {
//       backgroundColor: '#4caf50', // Change color on hover
//     },
//   },
// }));

// const CustomButton = ({ label }) => {
//   const classes = useStyles();

//   return (
//     <Button variant="contained" className={classes.button}>
//       {label}
//     </Button>
//   );
// };

// const App = () => {
//   return (
//     <div>
//       <CustomButton label="IT" />
//       <CustomButton label="Non-IT" />
//     </div>
//   );
// };

// export default App;

// import React from 'react'

// export default function Button() {
//   return (
//     <div>Button</div>
//   )
// }
