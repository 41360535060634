// import React from 'react'
import React from 'react';
// import Core from '../../courses/Core';
// import IT from '../../courses/IT';
// import NonIT from '../../courses/Non-IT';


// const DropdownMenu = () => {
//     return (
//       <div className="dropdown-menu">
//         <ul>
//           <li>IT</li>
//           <li>Non-IT</li>
//           <li>Core</li>
//         </ul>
//       </div>
//     );
//   };
// export default DropdownMenu


import { useState } from "react";
import Courses from "../../Courses/Courses";
import "../../../CSS/Courses/Courses.css";


function App() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  return (
    <div className="App">
      <header className="App-header">
        <div
          className="menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* <button></button> */}
          {/* <h2>Courses</h2> */}
          {/* <DropdownMenu /> */}
          {/* <p>Courses</p> */}
          Courses
          {isDropdownVisible && <Courses />}
        </div>
      </header>
    </div>
  );
}

export default App;


