import React from 'react'
import '../../CSS/ContactUs/serv.css'




const Services = () => {
  return (
    <div>
        <h1 className='headingh1'> Our Services </h1>
        <div className='para1-container'>
        <p className='para1'> 
        Myinception offers a wide range of recruitment solutions that are configurable to your business needs. Our well trained and domain specific consultants will ensure that we deliver the high standards of service and value you would expect from your recruitment service provider
        </p>
        </div>
    </div>
  )
}
    
export default Services
