
import React, { useState } from 'react';
import axios from 'axios';
import '../../CSS/AdminPage/AdminCsvFileUpload.css'


const AdminCsvFileUpload = () => {
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post('http://localhost:7000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('File uploaded successfully');

      document.getElementById('fileInput').value = '';

      setFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      
    }
  };

  return (
    <div>
      <form className='admin-csvfile' onSubmit={handleSubmit}>
        <input type="file" id='fileInput' onChange={handleChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default AdminCsvFileUpload;