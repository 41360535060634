// import Image1 from "../../../Images/CoursesImages/core images/embedded.jpg";
// import Image2 from "../../../Images/CoursesImages/core images/IoT.jpg";
// import Image3 from "../../../Images/CoursesImages/core images/LT-panel.jpg";
// import Image4 from "../../../Images/CoursesImages/core images/ht-panel.jpg";
// import Image5 from "../../../Images/CoursesImages/core images/scada.jpg";

import Image1 from "../../../Images/CoursesImages/core images/embedded-logo.png";
import Image2 from "../../../Images/CoursesImages/core images/IoT-logo.png";
import Image3 from "../../../Images/CoursesImages/core images/Lt.png";
import Image4 from "../../../Images/CoursesImages/core images/ht pl.png";
import Image5 from "../../../Images/CoursesImages/core images/plc1.png";

export const CoreDetails = [
  {
    image: Image1,
    heading: "Embedded Systems",
    object:
      "Delve into the realm of Embedded Systems with our comprehensive course. Perfect for beginners and those looking to deepen their knowledge, this program covers key concepts to make you proficient in designing and programming embedded systems.",
    coursesModules: [
      {
        id: 1,
        title: "Introduction to Embedded Systems",
        description: [
          {
            subTitle: "Definition and characteristics of embedded systems",
          },
          {
            subTitle:
              "Embedded system components: processors, memory, I/O devices",
          },
          {
            subTitle: "Embedded system architecture",
          },
        ],
      },
      {
        id: 2,
        title: "Microcontroller Basics",
        description: [
          {
            subTitle: "Overview of microcontrollers",
          },
          {
            subTitle: "Architecture of microcontrollers (e.g., ARM, AVR, PIC)",
          },
          {
            subTitle: "Embedded C programming basics",
          },
        ],
      },
      {
        id: 3,
        title: "Embedded System Development Tools",
        description: [
          {
            subTitle: "Introduction to development environments (IDEs)",
          },
          {
            subTitle: "Compiler, linker, debugger",
          },
          {
            subTitle: "Simulation and emulation tools",
          },
        ],
      },
      {
        id: 4,
        title: "Real-Time Operating Systems (RTOS)",
        description: [
          {
            subTitle: "Fundamentals of RTOS",
          },
          {
            subTitle: "Task scheduling and synchronization",
          },
          {
            subTitle: "RTOS examples (e.g., FreeRTOS, μC/OS)",
          },
        ],
      },
      {
        id: 5,
        title: "Interfacing Peripherals",
        description: [
          {
            subTitle: "Input/output interfacing techniques",
          },
          {
            subTitle: "Serial communication (UART, SPI, I2C)",
          },
          {
            subTitle: "Interrupt handling",
          },
        ],
      },
      {
        id: 6,
        title: "Embedded System Design",
        description: [
          {
            subTitle: "System design methodologies",
          },
          {
            subTitle: "Software engineering principles in embedded systems",
          },
          {
            subTitle: "Case studies and projects",
          },
        ],
      },
      {
        id: 7,
        title: "Embedded System Testing and Debugging",
        description: [
          {
            subTitle: "Testing strategies for embedded systems",
          },
          {
            subTitle: "Debugging techniques and tools",
          },
          {
            subTitle: "Code optimization for embedded systems",
          },
        ],
      },
      {
        id: 8,
        title: "Project Presentations and Evaluation",
        description: [
          {
            subTitle: "Students present their embedded system projects",
          },
          {
            subTitle: "Evaluation of project implementation and documentation",
          },
        ],
      },
    ],
    details: [
      {
        fee: "₹20,000 + GST",
        duration:
          "35 Days Training + 5 Days Industrial Training (Core Company)",
      },
    ],
    enrollNow:
      "Ready to explore the world of Embedded Systems? Enroll now and start your journey towards mastering the development of embedded software and hardware!",
  },
  {
    image: Image2,
    heading: "Internet of Things (IoT)",
    object:
      "Explore the exciting field of Internet of Things with our comprehensive course. Whether you're a beginner or looking to enhance your skills, this program covers key concepts to make you proficient in developing and deploying IoT solutions.",
    coursesModules: [
      {
        id: 1,
        title: "Introduction to IoT",
        description: [
          {
            subTitle: "Definition and concepts of IoT",
          },
          {
            subTitle: "Evolution and significance of IoT",
          },
          {
            subTitle: "IoT architecture and components",
          },
        ],
      },
      {
        id: 2,
        title: "IoT Devices and Sensors",
        description: [
          {
            subTitle: "Types of IoT devices (sensors, actuators)",
          },
          {
            subTitle: "Sensor technologies and classifications",
          },
          {
            subTitle:
              "Communication protocols for IoT devices (MQTT, CoAP, HTTP, etc.)",
          },
        ],
      },
      {
        id: 3,
        title: "IoT Communication Technologies",
        description: [
          {
            subTitle:
              "Wireless communication standards (Wi-Fi, Bluetooth, Zigbee, LoRa, NB-IoT)",
          },
          {
            subTitle: "IoT gateways and their role in data aggregation",
          },
        ],
      },
      {
        id: 4,
        title: "IoT Data Management",
        description: [
          {
            subTitle: "Data collection, storage, and processing in IoT",
          },
          {
            subTitle: "Edge computing and fog computing in IoT",
          },
          {
            subTitle: "IoT data security and privacy considerations",
          },
        ],
      },
      {
        id: 5,
        title: "IoT Platforms and Middleware",
        description: [
          {
            subTitle:
              "Introduction to IoT platforms (AWS IoT, Azure IoT, Google Cloud IoT, etc.)",
          },
          {
            subTitle: "Middleware for IoT systems",
          },
          {
            subTitle: "Device management and provisioning",
          },
        ],
      },
      {
        id: 6,
        title: "IoT Applications and Case Studies",
        description: [
          {
            subTitle: "Industrial IoT (IIoT) applications",
          },
          {
            subTitle:
              "Smart homes, healthcare, agriculture, and other IoT applications",
          },
          { subTitle: "Case studies and real-world examples" },
        ],
      },
      {
        id: 7,
        title: "IoT Development and Prototyping",
        description: [
          {
            subTitle:
              "Prototyping IoT solutions using development boards (Arduino, Raspberry Pi, etc.)",
          },
          {
            subTitle: "IoT application development using IoT platforms",
          },
          {
            subTitle:
              "Integration of sensors, actuators, and communication modules",
          },
        ],
      },
      {
        id: 8,
        title: "Project Work and Presentations",
        description: [
          {
            subTitle: "Students work on IoT projects",
          },
          {
            subTitle:
              "Presentation and evaluation of IoT project implementations",
          },
        ],
      },
    ],
    details: [
      {
        fee: "₹20,000 + GST",
        duration:
          "35 Days Training + 5 Days Industrial Training (Core Company)",
      },
    ],
    enrollNow:
      "Ready to dive into the world of IoT? Enroll now and embark on a journey to connect the physical and digital worlds through innovative IoT applications!",
  },
  {
    image: Image3,
    heading: "LT Panel Design",
    object:
      "Master LT Panel Design with our comprehensive course. Perfect for beginners and those looking to deepen their knowledge, this program covers key concepts to make you proficient in designing Low-Tension (LT) panels.",
    coursesModules: [
      {
        id: 1,
        title: "Introduction to Electrical Panels",
        description: [
          {
            subTitle: "Basics of electrical panels and their types",
          },
          {
            subTitle: "Overview of LT panels and their applications",
          },
          {
            subTitle:
              "Safety considerations in panel installation and operation",
          },
        ],
      },
      {
        id: 2,
        title: "Electrical Circuit Fundamentals",
        description: [
          {
            subTitle: "Understanding electrical circuits",
          },
          {
            subTitle: "Voltage, current, resistance, and power calculations",
          },
          {
            subTitle: "Ohm's Law and Kirchhoff's laws",
          },
        ],
      },
      {
        id: 3,
        title: "Components of LT Panels",
        description: [
          {
            subTitle:
              "Components and devices used in LT panels (MCBs, MCCBs, contactors, relays, etc.)",
          },
          {
            subTitle: "Circuit breakers and their functions",
          },
          {
            subTitle: "Switchgear and protection devices",
          },
        ],
      },
      {
        id: 4,
        title: "LT Panel Design and Configuration",
        description: [
          {
            subTitle: "Design considerations for LT panels",
          },
          {
            subTitle: "Sizing and selection of components",
          },
          {
            subTitle: "Wiring and layout design principles",
          },
        ],
      },
      {
        id: 5,
        title: "Installation and Commissioning",
        description: [
          {
            subTitle: "Installation procedures for LT panels",
          },
          {
            subTitle: "Testing and commissioning of LT panels",
          },
          {
            subTitle: "Troubleshooting common installation issues",
          },
        ],
      },
      {
        id: 6,
        title: "Safety and Maintenance",
        description: [
          {
            subTitle: "Safety practices in working with electrical panels",
          },
          {
            subTitle: "Preventive maintenance of LT panels",
          },
          {
            subTitle: "Identifying and rectifying faults in panels",
          },
        ],
      },
      {
        id: 7,
        title: "Regulatory Standards and Codes",
        description: [
          {
            subTitle:
              "Understanding electrical codes and standards related to LT panels",
          },
          {
            subTitle: "Compliance requirements and regulations",
          },
          {
            subTitle: "Case studies of non-compliance and safety hazards",
          },
        ],
      },
      {
        id: 8,
        title: "Advanced Topics",
        description: [
          {
            subTitle: "Energy management in LT panels",
          },
          {
            subTitle: "Remote monitoring and control systems for panels",
          },
          {
            subTitle: "Upgradation and modernization of LT panels",
          },
        ],
      },
      {
        id: 9,
        title: "Practical Projects and Assessment",
        description: [
          {
            subTitle:
              "Hands-on projects involving LT panel design, installation, or troubleshooting",
          },
          {
            subTitle: "Evaluation of practical skills and understanding",
          },
        ],
      },
    ],
    details: [
      {
        fee: "₹20,000 + GST",
        duration:
          "35 Days Training + 5 Days Industrial Training (Core Company)",
      },
    ],
    enrollNow:
      "Ready to become an expert in LT Panel Design? Enroll now and start your journey towards mastering the skills required for designing efficient and reliable LT panels!",
  },
  {
    image: Image4,
    heading: "HT Panel Design",
    object:
      "Explore the intricacies of High-Tension (HT) Panel Design with our comprehensive course. Whether you're a beginner or looking to enhance your skills, this program covers key concepts to make you proficient in designing High-Tension panels.",
    coursesModules: [
      {
        id: 1,
        title: "Introduction to High Tension Panels",
        description: [
          {
            subTitle:
              "Overview of HT panels and their role in power distribution",
          },
          {
            subTitle:
              "Safety considerations in handling high voltage equipment",
          },
          {
            subTitle: "Basic principles of HT panel operation",
          },
        ],
      },
      {
        id: 2,
        title: "High Voltage Components",
        description: [
          {
            subTitle:
              "Components used in HT panels (circuit breakers, transformers, isolators)",
          },
          {
            subTitle:
              "Types of circuit breakers and their applications in HT systems",
          },
          {
            subTitle: "Transformer selection and configuration for HT panels",
          },
        ],
      },
      {
        id: 3,
        title: "Design Principles of HT Panels",
        description: [
          {
            subTitle: "Design considerations for HT panels",
          },
          {
            subTitle:
              "Sizing and selection of components for different applications",
          },
          {
            subTitle: "Wiring diagrams and layout planning for HT panels",
          },
        ],
      },
      {
        id: 4,
        title: "Installation and Commissioning",
        description: [
          {
            subTitle: "Procedures for installing HT panels",
          },
          {
            subTitle: "Testing and commissioning of HT panels",
          },
          {
            subTitle: "Safety measures during installation and commissioning",
          },
        ],
      },
      {
        id: 5,
        title: "Protection and Control Systems",
        description: [
          {
            subTitle:
              "Protection schemes in HT panels (overcurrent, differential, etc.)",
          },
          {
            subTitle: "Control systems and monitoring in HT panels",
          },
          {
            subTitle:
              "SCADA (Supervisory Control and Data Acquisition) systems for HT panels",
          },
        ],
      },
      {
        id: 6,
        title: "Maintenance and Troubleshooting",
        description: [
          {
            subTitle: "Preventive maintenance of HT panels",
          },
          {
            subTitle: "Common issues and troubleshooting in HT panels",
          },
          {
            subTitle: "Diagnostic techniques for identifying faults",
          },
        ],
      },
      {
        id: 7,
        title: "Regulatory Standards and Compliance",
        description: [
          {
            subTitle: "Understanding relevant electrical codes and standards",
          },
          {
            subTitle: "Compliance requirements for HT panels",
          },
          {
            subTitle: "Safety regulations and legal obligations",
          },
        ],
      },
      {
        id: 8,
        title: "Project Work and Presentations",
        description: [
          {
            subTitle: "Students work on HT panel projects",
          },
          {
            subTitle: "Presentation and evaluation of project implementations",
          },
        ],
      },
    ],
    details: [
      {
        fee: "₹20,000 + GST",
        duration:
          "35 Days Training + 5 Days Industrial Training (Core Company)",
      },
    ],
    enrollNow:
      "Ready to become an expert in HT Panel Design? Enroll now and embark on a journey to master the skills needed for designing robust and efficient High-Tension panels!",
  },
  {
    image: Image5,
    heading: "PLC/SCADA",
    object:
      "Master PLC (Programmable Logic Controller) and SCADA (Supervisory Control and Data Acquisition) with our comprehensive course. Whether you're a beginner or looking to deepen your knowledge, this program covers key concepts to make you proficient in industrial automation.",
    coursesModules: [
      {
        id: 1,
        subHeading: "Programmable Logic Controller (PLC) Course Syllabus",
        title: "Introduction to PLCs",
        description: [
          {
            subTitle:
              "Overview of PLCs and their role in industrial automation",
          },
          {
            subTitle: "Evolution and significance of PLC technology",
          },
          {
            subTitle: "Basic components and architecture of a PLC system",
          },
        ],
      },
      {
        id: 2,
        title: "PLC Programming Basics",
        description: [
          {
            subTitle: "Introduction to ladder logic programming",
          },
          {
            subTitle:
              "Programming languages used in PLCs (Ladder Diagram, Function Block Diagram, Structured Text)",
          },
          {
            subTitle: "Basic instructions: input/output, timers, counters",
          },
        ],
      },
      {
        id: 3,
        title: "Advanced PLC Programming",
        description: [
          {
            subTitle:
              "Data handling instructions (move, compare, arithmetic operations)",
          },
          {
            subTitle: "Sequential function charts (SFC) and their applications",
          },
          {
            subTitle: "PLC memory organization and addressing",
          },
        ],
      },
      {
        id: 4,
        title: "PLC Hardware and Communication",
        description: [
          {
            subTitle:
              "Types of PLCs and their characteristics (Compact, Modular, etc.)",
          },
          {
            subTitle: "I/O modules and their configurations",
          },
          {
            subTitle: "Communication protocols used in PLC systems",
          },
        ],
      },
      {
        id: 5,
        title: "PLC Networking and Integration",
        description: [
          {
            subTitle:
              "Industrial networks and their role in PLC communication (Profibus, Ethernet/IP, Modbus, etc.)",
          },
          {
            subTitle: "PLC-HMI (Human-Machine Interface) integration",
          },
          {
            subTitle: "Remote I/O and distributed control systems",
          },
        ],
      },
      {
        id: 6,
        title: "PLC Applications and Case Studies",
        description: [
          {
            subTitle:
              "Applications of PLCs in various industries (manufacturing, automotive, process control, etc.)",
          },
          {
            subTitle: "Case studies of real-world PLC applications",
          },
          {
            subTitle: "Design considerations for PLC-based systems",
          },
        ],
      },
      {
        id: 7,
        title: "PLC Troubleshooting and Maintenance",
        description: [
          {
            subTitle: "Troubleshooting techniques for PLC systems",
          },
          {
            subTitle: "Preventive maintenance of PLCs",
          },
          {
            subTitle: "Diagnostic tools and software for PLCs",
          },
        ],
      },
      {
        id: 8,
        title: "Project Work and Presentations",
        description: [
          {
            subTitle: "Students work on PLC-based projects",
          },
          {
            subTitle: "Presentation and evaluation of project implementations",
          },
        ],
      },
      {
        id: 1,
        subHeading:
          "SCADA (Supervisory Control and Data Acquisition) Course Syllabus",
        title: "Introduction to SCADA Systems",
        description: [
          {
            subTitle: "Overview of SCADA and its role in industrial automation",
          },
          {
            subTitle: "Evolution and significance of SCADA technology",
          },
          {
            subTitle: "Basic components and architecture of a SCADA system",
          },
        ],
      },
      {
        id: 2,
        title: "SCADA Software and Configuration",
        description: [
          {
            subTitle:
              "Understanding SCADA software platforms (e.g., Wonderware, Ignition, Siemens WinCC)",
          },
          {
            subTitle:
              "Configuration of SCADA systems: creating tags, screens, and alarms",
          },
          {
            subTitle: "Data acquisition and processing in SCADA",
          },
        ],
      },
      {
        id: 3,
        title: "SCADA Communication Protocols",
        description: [
          {
            subTitle:
              "Communication protocols used in SCADA systems (OPC, Modbus, DNP3, etc.)",
          },
          {
            subTitle:
              "Remote terminal units (RTUs) and their role in SCADA communication",
          },
          {
            subTitle:
              "Integration of various communication protocols in SCADA networks",
          },
        ],
      },{
        id:4,
        title:"Human-Machine Interface (HMI) Design",
        description:[{
          subTitle:"Design principles for SCADA/HMI interfaces"
        },{
          subTitle:"Creating intuitive and effective HMI screens"
        },{
          subTitle:"Alarm management and visualization in SCADA"
        }
        ],
      },{
        id:5,
        title:"SCADA Networking and Security",
        description:[{
          subTitle:"Industrial networks and their role in SCADA communication",
        },{
          subTitle:"Cybersecurity considerations for SCADA systems"
        },{
          subTitle:"Best practices for securing SCADA networks"
        }]
      },{
        id:6,
        title:"SCADA Applications and Case Studies",
        description:[{
          subTitle:"Applications of SCADA in different industries (energy, manufacturing, water treatment, etc.)"
        },{
          subTitle:"Case studies of real-world SCADA applications"
        },{
          subTitle:"Designing SCADA systems for specific industrial requirements"
        }
      ]
      },{
        id:7,
        title:"SCADA Troubleshooting and Maintenance",
        description:[{
          subTitle:"Troubleshooting techniques for SCADA systems"
        },{
          subTitle:"Preventive maintenance of SCADA systems"
        },{
          subTitle:"Diagnostic tools and software for SCADA"
        }]
      },
      {
        id:8,
        title:"Project Work and Presentations",
        description:[{
          subTitle:"Students work on SCADA-based projects"
        },{
          subTitle:"Presentation and evaluation of project implementations"
        }]
      }
    ],
    details: [
      {
        fee: "₹20,000 + GST",
        duration:
          "35 Days Training + 5 Days Industrial Training (Core Company)",
      },
    ],
    enrollNow:
      "Ready to excel in PLC/SCADA? Enroll now and start your journey towards mastering the automation technologies used in industries worldwide!",
  },
];
