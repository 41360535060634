import React, { useState } from 'react';
import ReactModal from 'react-modal';
import  ITDetails  from '../../Courses/IT/ITDetails'; 
import '../../../CSS/Courses/Courses.css'
import LayOut from '../../Home/NavigationBar/LayOut';
import Button from '@mui/material/Button';
import { IoMdCloseCircle } from "react-icons/io";
import { Helmet } from "react-helmet";

const IT = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal=() =>{
    setIsModalOpen(true);
    document.body.classList.add("modal-open")
  }
  const closeModal =() =>{
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedCourse(null)

  }

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    // setIsModalOpen(true);
    openModal();
  };

  // const closeModal = () => {
  //   setSelectedCourse(null); 
  //   setIsModalOpen(false);
  // };

  return (
    <div>
    <Helmet>
          <title>MyInception Tech - IT Courses - Programming, Full Stack, AI & More</title>
          <meta name="title" content="MyInception Tech - IT Courses - Programming, Full Stack, AI & More" />
          <meta name="description" content="Discover a comprehensive selection of IT courses at MyInception Tech, including C Programming , C++ , Java , Python , Machine Learning , Data Science , Blockchain , Cloud Computing , and Full Stack Development. Our expert-led training programs cover cutting-edge technologies like React, Angular, Node.js, and ASP.Net Core MVC. Whether you're looking to master programming, data analytics, AI, or digital marketing, we provide hands-on learning and career-focused certifications to boost your IT career."/>
          <meta name="keywords" content=" C programming course , C Programming courses in chennai , C++ programming course , C++ programming courses in chennai , Java programming course , Java programming courses in chennai , Python programming course , Python programming courses in chennai , Machine Learning course , Machine Learning courses in chennai , React JS course , React JS courses in chennai , Angular training course , Angular training courses in chennai , Node.js training courses , Node.js training courses in chennai , Data Science course , Data Science courses in chennai , AI training , Blockchain , Cloud Computing AWS , Digital Marketing course , Digital Marketing courses in chennai , Full Stack Development course , Python Fullstack course , Python Fullstack course in chennai , React Fullstack course , React Fullstack courses in chennai , Java Fullstack course , Java Fullstack courses in chennai , ASP.Net Core MVC course , ASP.Net Core MVC courses in chennai , Python Internship , React JS Internship , Internship in chennai , Internship , Certificate Internship"/>
          <meta name="robots" content=" index , follow , max-image-preview:large , max-snippet:-1"/>
          <meta name="google-site-verification" content="WHDj4Vlf9U0gHTojE3bGdbdE9lIaD-WEtGmVWsVtJP4" />
          <link rel="canonical" href="https://myinceptiontech.com/courses/it" />
          <meta property="og:title" content="MyInception Tech - IT Courses - Programming, Full Stack, AI & More"/>
          <meta property="og:description" content="Discover a comprehensive selection of IT courses at MyInception Tech, including C Programming , C++ , Java , Python , Machine Learning , Data Science , Blockchain , Cloud Computing , and Full Stack Development. Our expert-led training programs cover cutting-edge technologies like React, Angular, Node.js, and ASP.Net Core MVC. Whether you're looking to master programming, data analytics, AI, or digital marketing, we provide hands-on learning and career-focused certifications to boost your IT career."/>
          <meta property="og:url" content="https://myinceptiontech.com/courses/it"/>
          <meta property="og:type" content="website"/>
          <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "MyInception Tech - IT Courses - Programming, Full Stack, AI & More",
              "description": "Discover a comprehensive selection of IT courses at MyInception Tech, including C Programming, C++, Java, Python, Machine Learning, Data Science, Blockchain, Cloud Computing, and Full Stack Development. Our expert-led training programs cover cutting-edge technologies like React, Angular, Node.js, and ASP.Net Core MVC. Whether you're looking to master programming, data analytics, AI, or digital marketing, we provide hands-on learning and career-focused certifications to boost your IT career.",
              "url": "https://myinceptiontech.com/courses/it",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.myinceptiontech.in/?s={search_term}",
              "query-input":  "required name=search_term",
              },
            }
          `}
          </script>
          {/* Google Tag Manager (GTM) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GXE5MZYZZM');
          `}
        </script>
        </Helmet>
    <LayOut>
    <div >

      <h2 className='courses-heading'>IT Courses </h2>
      <div className="course-container">
      {ITDetails.map(course => (
        <div key={course.heading}>
           {console.log( course.heading)}
          <img src={course.image} alt={course.heading} onClick={() => handleCourseClick(course)} />
          <h3 onClick={() => handleCourseClick(course)}>{course.heading}</h3>
        </div>
      ))}
</div>
      <ReactModal 
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Course Details Modal"
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            transform: "translate(0% ,5%)",
          },
          maxWidth : "80vw", 
          
        }}
      >
        {selectedCourse && (
          <div style={{maxWidth: '75vw', overflow:"auto"}}>
           
            <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
            <h2 className='popup-Title'>{selectedCourse.heading}</h2>
            <p>{selectedCourse.object}</p>
            <h3>Course Modules</h3>
            <ol>
              {selectedCourse.courseModules.map(module => (
                <li key={module.id}>
                  <h4>{module.title}</h4>
                  <ul>
                    {module.description && module.description.map((desc, index) => (
                      <li key={index}>
                        <p>{desc.subTitle}</p>
                        {desc.Topics && (
                          <ul>
                            {desc.Topics.map((topic, index) => (
                             <li key={index}> {topic.subTopic && topic.subTopic}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
            {/* <p>Fees: {selectedCourse.details[0].fee}</p> */}
            <p>Duration: {selectedCourse.details[0].duration}</p>
            <p>{selectedCourse.enrollNow}</p>
            <div className="courseClose">
            <Button variant="contained" color="primary" onClick={closeModal} > Close </Button>
            </div>
           
          </div>
        )}
      </ReactModal>
    </div>
    </LayOut>
    </div>
  );
};

export default IT;




