import React from "react";
import "./aboutUs.css";
import image1 from "../../Images/AboutUs/about1.png";
import image2 from "../../Images/AboutUs/about2.png";
import image3 from "../../Images/AboutUs/vision.png";
import core from "../../Images/AboutUs/core.png";
import ModalWindow from "../../Reusuable_components/ModalWindow";
import LayOut from "../Home/NavigationBar/LayOut";
import { Helmet } from 'react-helmet';


function AboutUsContent() {
  const popupContent = [
    {
      heading: "ABOUT US",
      title: "Empowering Careers and Businesses",
      paragraph:
        "MyInception Tech, founded in 2009 in Chennai, stands as a beacon of excellence in both the IT and core sectors. We have earned recognition for our exceptional professional recruitment services. Our expertise in conducting Campus placements, job fairs, and training programs have solidified our reputation among the leading colleges, corporate entities, and industries nationwide.",
    },
    {
      title: "Our Commitment to Training and Placement Excellence",
      paragraph:
        "MyInception Tech's unwavering commitment lies in providing exceptional training and placement services. We empower aspiring professionals with the knowledge, skills, and opportunities essential for success in the ever-evolving realms of technology and core industries. Our proven track record in these endeavors has garnered acclaim from prestigious colleges, top-tier corporate entities, and diverse industries nationwide.",
    },
    {
      title: "Meet Our Team of Experts",
      paragraph:
        "Behind every success story at MyInception Tech stands a team of seasoned professionals. More than educators, our team serves as mentors who guide, inspire, and support you on your journey. With a wealth of industry knowledge and a passion for technology and learning, they are your trusted partners in achieving your goals.",
    },
    {
      title: "Excellence in IT and Management",
      paragraph:
        "Our expertise spans a wide array of industries, with a specialized focus on IT and management. MyInception Tech is proud to be associated with experts who have excelled at esteemed institutions. Their depth of knowledge and experience sets us apart, enabling us to deliver unparalleled training and placement services tailored for both the IT and core sectors.",
    },
  ];

  return (
    <div>
      <Helmet>
      <title>MyInception Tech - About Us - Leading Training and Placement Services</title>
          <meta name="title" content="MyInception Tech - About Us - Leading Training and Placement Services"/>
          <meta name="description" content="MyInception Tech, established in 2009 in Chennai, offers training and placement services in IT and core sectors. We assist aspiring professionals through campus placements and job fairs, helping them achieve their career goals."/>
          <meta name="keywords" content=" MyInception Tech , training and placement services , IT training , non-IT training , career development , HR training , job placement , technology careers , core industry careers , professional development , skill enhancement , job seeker support , Internship ,  Campus Placements , placement and training"/>
          <meta name="robots" content=" index, follow , max-image-preview:large , max-snippet:-1"/>
          <meta name="google-site-verification" content="WHDj4Vlf9U0gHTojE3bGdbdE9lIaD-WEtGmVWsVtJP4" />
          <link rel="canonical" href="https://myinceptiontech.com/about/" />
          <meta property="og:title" content="MyInception Tech - About Us - Leading Training and Placement Services"/>
          <meta property="og:description" content="MyInception Tech, established in 2009 in Chennai, offers training and placement services in IT and core sectors. We assist aspiring professionals through campus placements and job fairs, helping them achieve their career goals."/>
          <meta property="og:image" content="https://myinceptiontech.com/static/media/vision.a2e6133d73a61b1132ec.png"/>
          <meta property="og:image" content="https://myinceptiontech.com/static/media/about1.660f04ec8e148607bc94.png"/>
          <meta property="og:image" content="https://myinceptiontech.com/static/media/about2.59690a41634e99a1beef.png"/>
          <meta property="og:image" content="https://myinceptiontech.com/static/media/core.53b0c80ca4672e609858.png"/>
          <meta property="og:url" content="https://myinceptiontech.com/about/"/>
          <meta property="og:type" content="website"/>
          <meta property="og:site_name" content="MyInception Tech: Training and Placement Consultancy" />
          <meta property="article:publisher" content="https://www.facebook.com/myinceptiontech" />
          <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "url": "https://myinceptiontech.com/about",
              "name": "MyInception Tech - About Us - Leading Training and Placement Services",
              "description": "MyInception Tech, established in 2009 in Chennai, offers training and placement services in IT and core sectors. We assist aspiring professionals through campus placements and job fairs, helping them achieve their career goals.",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.myinceptiontech.in/?s={search_term}",
              "query-input": "required name=search_term", 
              },
            }
          `}
          </script>
          {/* Google Tag Manager (GTM) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GXE5MZYZZM');
          `}
        </script>
      </Helmet>
    <LayOut>
      <div class="aboutUs">
        <div>
          <h2 className="page-heading">ABOUT US</h2>
          <div className="aboutPage">
            <div className="aboutImageContainer">
              <img src={image1} alt={"about"} className="aboutPageImage" />
            </div>
            <div className="aboutPageContent">
              <h3>Empowering Careers and Businesses</h3>
              <p>
                MyInception Tech, founded in 2009 in Chennai, stands as a beacon
                of excellence in both the IT and core sectors. We have earned
                recognition for our exceptional professional recruitment
                services. Our expertise in conducting Campus placements, job
                fairs, and training programs have solidified our reputation
                among the leading colleges, corporate entities, and industries
                nationwide.
              </p>
              <ModalWindow
                triggerText="Read more..."
                customContent= {popupContent}
              />
            </div>
          </div>

          <div className="principles">
            <div className="principles-list">
              <div className="About-images">
                <img src={image3} alt={"about"} className="principles-image" />
              </div>
              <div className="About-content">
                <h3 className="principles-title">Our Vision </h3>

                <p>
                  Our vision extends beyond borders. We aspire to provide
                  end-to-end, integrated human resources solutions for
                  organizations around the world. MyInception Tech is committed
                  to becoming a global leader in training and placement
                  services. Our team comprises individuals from diverse
                  backgrounds, each with a rich history of holding key positions
                  in recruitment and training across major companies.
                </p>
              </div>
            </div>

            <div className="principles-list">
              <div className="About-images">
                <img src={image2} alt={"about"} className="principles-image" />
              </div>
              <div className="About-content">
                <h3 className="principles-title">Our Mission </h3>

                <p>
                  Our primary mission is to provide integrated, strategic
                  corporate, and consulting solutions across various verticals
                  in different organizations. We believe in the philosophy that
                  "ensuring customer satisfaction is our top priority." This
                  principle guides us on our path to success and has been
                  instrumental in establishing us as a reputable organization.
                </p>
              </div>
            </div>

            <div className="principles-list">
              <div className="About-images">
                <img src={core} alt={"about"} className="principles-image" />
              </div>
              <div className="About-content">
                <h3 className="principles-title">Core Values </h3>

                <p>
                  Our values are deeply ingrained in every aspect of our work.
                  We're dedicated to offering complete training and placement
                  solutions to organizations worldwide. The depth and breadth of
                  experience within our team span every industry, and our
                  understanding of local markets makes us invaluable partners in
                  your journey.
                </p>
              </div>
            </div>
          </div>
          <div className="end-line">
            <h3>Join Us - Where Excellence Meets Opportunity</h3>
          </div>
        </div>
      </div>
    </LayOut>
    </div>
  );
}

export default AboutUsContent;
