import Image1 from "../../../Images/CoursesImages/non-it/HR Recruitment logo.png";
import Image2 from "../../../Images/CoursesImages/non-it/Process.png";
import Image3 from "../../../Images/CoursesImages/non-it/Content.png";

export const NonITDetails = [
  {
    image: Image1,
    heading: " HR & payroll Training",
    object:
      "Unlock the skills needed for successful HR recruitment with our comprehensive course. Perfect for beginners and those looking to enhance their skills, this program covers key concepts to make you proficient in the art and science of recruiting.",
    courseModules: [
      {
        id: 1,
        title: "Recruitment Cycle",
        objective: "Learn the ins and outs of hiring employees, from sourcing candidates to making job offers. Gain practical skills to improve your recruitment process.",
        description: [
          {
            subTitle: "Introduction to Recruitment Cycle",
          },
          {
            subTitle: "Job Analysis and Role Definition",
          },
          {
            subTitle: "Sourcing Strategies",
          },
          {
            subTitle: "Candidate Screening and Selection",
          },
          {
            subTitle: "Interviewing Techniques",
          },
          {
            subTitle: "Assessment and Evaluation",
          },
          {
            subTitle: "Offer Management and Onboarding",
          },
          {
            subTitle: "Employment Law and Compliance",
          },
          {
            subTitle: "Technology in Recruitment",
          },
          {
            subTitle: "Diversity and Inclusion in Recruitment",
          },
          {
            subTitle: "Talent Pipeline and Succession Planning",
          },
          {
            subTitle: "Continuous Improvement and Metrics",
          },
        ],
      },
      {
        id: 2,
        title: "Sourcing from job portals",
        objective:"Learn how to find the right candidates using job portals. Get hands-on with search techniques and engagement strategies to boost your recruitment success.",
        description: [
          {
            subTitle: "Introduction to Job Portals",
          },
          {
            subTitle: "Using Job Portal Features",
          },{
            subTitle:"Writing Effective Job Posts"
          },{
            subTitle:"Searching for Candidates"
          },{
            subTitle:"Sorting and Filtering Candidates"
          },{
            subTitle:"Interacting with Candidates"
          },{
            subTitle:"Managing Applications"
          },{
            subTitle:"Reviewing Candidate Profiles"
          },{
            subTitle:"Screening Candidates"
          },{
            subTitle:"Using Applicant Tracking Systems (ATS)"
          },{
            subTitle:"Improving Efficiency"
          },{
            subTitle:"Best Practices for Successful Sourcing"
          }
        ],
      },
      {
        id: 3,
        title: "Payroll Processing",
        objective:"Master the essentials of payroll processing, from calculating wages to managing deductions and taxes. Gain practical skills to ensure accurate and timely payroll administration.",
        description: [
          {
            subTitle: "Introduction to Payroll",
          },
          {
            subTitle: "Understanding Employee Data",
          },
          {
            subTitle:"Calculating Pay"
          },{
            subTitle:"Deductions and Taxes"
          },{
            subTitle:"Overtime and Special Pay"
          },{
            subTitle:"Leaves and Absences"
          },{
            subTitle:"Timekeeping"
          },{
            subTitle:"Payroll Software Basics"
          },{
            subTitle:"Generating Reports"
          },{
            subTitle:"Legal Compliance"
          },{
            subTitle:"Handling Errors"
          },{
            subTitle:"Best Practices"
          }
        ],
      },
      {
        id: 4,
        title: "Head Hunting",
        objective:"Explore the art of headhunting and learn how to identify, engage, and recruit top talent directly. Develop effective strategies for finding and attracting candidates to fill key positions.",
        description: [
          {
            subTitle: "Introduction to Headhunting",
          },
          {
            subTitle: "Understanding Client Needs",
          },{
            subTitle:"Finding Potential Candidates"
          },{
            subTitle:"Research and Mapping"
          },{
            subTitle:"Reaching Out to Candidates"
          },{
            subTitle:"Engaging Candidates"
          },{
            subTitle:"Screening Candidates"
          },{
            subTitle:"Presenting Candidates to Clients"
          },{
            subTitle:"Negotiation and Offers"
          },{
            subTitle:"Assisting with Onboarding"
          },{
            subTitle:"Building Client Relationships"
          },{
            subTitle:"Improving Headhunting Skills"
          }
        ],
      },
      {
        id: 5,
        title: "Employee Stabilization",
        objective:"Learn methods to keep employees happy and committed. Gain skills to reduce turnover and create a stable work environment.",
        description: [
          {
            subTitle: "Introduction to Employee Stabilization",
          },
          {
            subTitle: "Understanding Retention Challenges",
          },{
            subTitle:"Identifying Factors Affecting Stability"
          },{
            subTitle:"Creating Retention Strategies"
          },{
            subTitle:"Effective Onboarding"
          },{
            subTitle:"Enhancing Employee Engagement"
          },{
            subTitle:"Implementing Recognition and Rewards"
          },{
            subTitle:"Supporting Career Development"
          },{
            subTitle:"Improving Performance Management"
          },{
            subTitle:"Promoting Work-Life Balance"
          },{
            subTitle:"Conducting Exit Interviews"
          },{
            subTitle:"Continuous Improvement in Stabilization"
          }
        ],
      },
      {
        id: 6,
        title: "Exit Interview ",
        Objective:"Explore the purpose and process of exit interviews. Learn how to conduct effective interviews, gather valuable feedback, and use insights to improve organizational retention strategies.",
        description: [
          {
            subTitle: "Introduction to Exit Interviews",
          },
          {
            subTitle: "Purpose and Importance",
          },{
            subTitle:"Planning and Preparation"
          },{
            subTitle:"Conducting Exit Interviews"
          },{
            subTitle:"Questioning Techniques"
          },{
            subTitle:"Active Listening Skills"
          },{
            subTitle:"Handling Emotional Responses"
          },{
            subTitle:"Documenting Feedback"
          },{
            subTitle:"Analyzing Exit Interview Data"
          },{
            subTitle:"Reporting and Action Planning"
          },{
            subTitle:"Improving Exit Interview Processes"
          },{
            subTitle:"Best Practices for Successful Exits"
          }
        ],
      },
    ],
    details: {
      fees: "₹15,000 + GST",
      duration: " 30 Days",
    },
    enrollNow:
      "Ready to excel in HR recruitment? Enroll now and embark on a journey to become a skilled HR Recruiter, connecting talent with opportunity!",
  },
  {
    image: Image2,
    heading: " Process Associate (Supply Chain Management)",
    object:
      "Become proficient in Supply Chain Management as a Process Associate with our comprehensive course. Whether you're a beginner or seeking to enhance your skills, this program covers key concepts to make you adept in managing and optimizing supply chain processes.",
    courseModules: [
      {
        id: 1,
        title: "Introduction to Supply Chain Management",
        description: [
          {
            subTitle: "Overview of supply chain functions.",
          },
          {
            subTitle: "Importance of efficient supply chain processes.",
          },
        ],
      },
      {
        id: 2,
        title: "Freight Forwarding",
        description: [
          {
            subTitle: "Understanding freight logistics",
          },
          {
            subTitle: "Role of freight forwarding in supply chain",
          },
        ],
      },
      {
        id: 3,
        title: "Warehouse Management",
        description: [
          {
            subTitle: "Principles of effective warehouse operations",
          },
          {
            subTitle: "Inventory placement and organization in warehouses",
          },
        ],
      },
      {
        id: 4,
        title: "Inventory Management",
        description: [
          {
            subTitle: "Basics of inventory control.",
          },
          {
            subTitle: "Techniques for optimizing inventory levels",
          },
        ],
      },
      {
        id: 5,
        title: "Store Management",
        description: [
          {
            subTitle: "Store layout and design.",
          },
          {
            subTitle: "Inventory tracking and replenishment in stores",
          },
        ],
      },
      {
        id: 6,
        title: "Sales Order Processing",
        description: [
          {
            subTitle: "Sales order fulfillment process.",
          },
          {
            subTitle: "Order processing and tracking.",
          },
        ],
      },
      {
        id: 7,
        title: "Purchase Order Management",
        description: [
          {
            subTitle: "Creating and managing purchase orders.",
          },
          {
            subTitle: "Supplier relationship management.",
          },
        ],
      },
      {
        id: 8,
        title: "Supply Chain Analytics",
        description: [
          {
            subTitle: "Introduction to data analytics in supply chain",
          },
          {
            subTitle: "Analyzing and interpreting supply chain data",
          },
        ],
      },
      {
        id: 9,
        title: "Risk Management in Supply Chain",
        description: [
          {
            subTitle: "Identifying and mitigating supply chain risks",
          },
          {
            subTitle: "Contingency planning for disruptions",
          },
        ],
      },
      {
        id: 10,
        title: "Technology in Supply Chain",
        description: [
          {
            subTitle: "Role of technology in modern supply chain management",
          },
          {
            subTitle: "Overview of supply chain management software",
          },
        ],
      },
      {
        id: 11,
        title: "Regulatory Compliance in Supply Chain",
        description: [
          {
            subTitle: "Understanding industry regulations",
          },
          {
            subTitle: "Compliance considerations in supply chain processes",
          },
        ],
      },
      {
        id: 12,
        title: "Emerging Trends in Supply Chain Management",
        description: [
          {
            subTitle: "Exploring current and future trends in supply chain",
          },
          {
            subTitle: "Innovations shaping the supply chain landscape",
          },
        ],
      },
    ],
    details: {
      fees: "₹15,000 + GST",
      duration: " 20 Days",
    },
    enrollNow:
      "Ready to step into the world of Supply Chain Management as a Process Associate? Enroll now and embark on a journey to master the skills necessary for efficient and effective supply chain operations!",
  },
  {
    image: Image3,
    heading: " Content Writing",
    object:
      "Unlock the art and science of content creation with our comprehensive Content Writing course. Whether you're a beginner or seeking to refine your skills, this program covers key concepts to make you proficient in crafting engaging and effective written content.",
    courseModules: [
      {
        id: 1,
        title: "Introduction to Content Writing",
        description: [
          {
            subTitle: "Overview of the Content Writing landscape",
          },
          {
            subTitle: "Importance of effective content in various industries",
          },
        ],
      },
      {
        id: 2,
        title: "Understanding Your Audience",
        description: [
          {
            subTitle: "Identifying target audiences",
          },
          {
            subTitle: "Analyzing audience preferences and behavior",
          },
        ],
      },
      {
        id: 3,
        title: "Essentials of Good Writing",
        description: [
          {
            subTitle: "Grammar and punctuation",
          },
          {
            subTitle: "Clarity and conciseness",
          },
          {
            subTitle: "Style and tone in writing",
          },
        ],
      },
      {
        id: 4,
        title: "Research Skills for Writers",
        description: [
          {
            subTitle: "Effective methods for research",
          },
          {
            subTitle: "Fact-checking and source evaluation",
          },
        ],
      },
      {
        id: 5,
        title: "Content Planning and Organization",
        description: [
          {
            subTitle: "Outlining and structuring content",
          },
          {
            subTitle: "Creating compelling introductions and conclusions",
          },
        ],
      },
      {
        id: 6,
        title: "SEO Writing Techniques",
        description: [
          {
            subTitle: "Basics of Search Engine Optimization (SEO)",
          },
          {
            subTitle: "Incorporating keywords naturally into content",
          },
        ],
      },
      {
        id: 7,
        title: "Creating Engaging Headlines and Subheadings",
        description: [
          {
            subTitle: "Techniques for crafting attention-grabbing titles",
          },
          {
            subTitle: "Importance of subheadings in content",
          },
        ],
      },
      {
        id: 8,
        title: "Understanding Different Content Types",
        description: [
          {
            subTitle: "Blog posts, articles, press releases, and more",
          },
          {
            subTitle: "Tailoring content for different platforms",
          },
        ],
      },
      {
        id: 9,
        title: "Editing and Proofreading",
        description: [
          {
            subTitle: "Importance of editing in content creation",
          },
          {
            subTitle: "Techniques for effective proofreading",
          },
        ],
      },
      {
        id: 10,
        title: "Building a Writing Portfolio",
        description: [
          {
            subTitle: "Showcasing your work effectively",
          },
          {
            subTitle: "Creating a professional writing portfolio",
          },
        ],
      },
      {
        id: 11,
        title: "Freelance Writing and Opportunities",
        description: [
          {
            subTitle: "Exploring freelance writing opportunities",
          },
          {
            subTitle: "Navigating the freelancing landscape",
          },
        ],
      },
      {
        id: 12,
        title: "Content Marketing Basics",
        description: [
          {
            subTitle: "Introduction to content marketing principles",
          },
          {
            subTitle: "Integrating content writing into marketing strategies",
          },
        ],
      },
    ],
    details: {
      fees: "₹25,000 + GST",
      duration: " 30 Days",
    },
    enrollNow:
      "Ready to become a skilled content writer? Enroll now and embark on a journey to develop your writing skills and create impactful content!",
  },
];
