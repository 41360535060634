// import React, { useState, useEffect, useRef } from "react";
// import { IoMdCloseCircle } from "react-icons/io";
// import Popup from "reactjs-popup";
// import Button from '@mui/material/Button';


// const PopupModel = ({ children, submitButton }) => {
//   const [popupWidth, setPopupWidth] = useState('80%');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const popupContainerRef = useRef(null);

//   useEffect(() => {
//     function handleResize() {
//       if (window.innerWidth <= 600) {
//         setPopupWidth('90%');
//       } else {
//         setPopupWidth('60%');
//       }
//     }

//     window.addEventListener('resize', handleResize);
//     handleResize();

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//   };

//   return (
//     <>
//       <Button variant="contained" color="primary" onClick={openModal}>
//         Signup
//       </Button>
//       <Popup
//         modal
//         nested
//         open={isModalOpen}
//         onClose={closeModal}
//         closeOnDocumentClick={false}
//         contentStyle={{ width: popupWidth }}
//       >
//         <div className="popup-container" ref={popupContainerRef}>
//           <div className="closePopupButton" onClick={closeModal}>
//             <IoMdCloseCircle />
//           </div>
//           <div className="popup-display">{children}</div>
//           <div className="popup-button">
//           {submitButton}
//             {/* <Button variant="contained" color="primary" >
//               submit
//             </Button> */}
//             <Button variant="contained" color="primary" onClick={closeModal}>
//               Close
//             </Button>
//           </div>
        

//         </div>
//       </Popup>
//     </>
//   );
// };

// export default PopupModel;



// import React, { useState, useEffect, useRef } from "react";
// import { IoMdCloseCircle } from "react-icons/io";
// import Popup from "reactjs-popup";
// import Button from "@mui/material/Button";

// let uniquePopupId = 0;

// const PopupModel = ({ children, submitButton }) => {
//   const [popupWidth, setPopupWidth] = useState("80%");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [popupId, setPopupId] = useState(null); // Unique popup ID
//   const popupContainerRef = useRef(null);

//   // Handle responsive popup width
//   useEffect(() => {
//     function handleResize() {
//       setPopupWidth(window.innerWidth <= 600 ? "90%" : "60%");
//     }
//     window.addEventListener("resize", handleResize);
//     handleResize();
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//     // Handle interval to trigger popup every 2 minutes (120,000 ms)
//     useEffect(() => {
//         const intervalId = setInterval(() => {
//           setIsModalOpen(true);
//           setPopupId(`popup-${++uniquePopupId}`); // Assign a unique ID for each popup
//           document.body.classList.add("modal-open");
//         }, 60000); // 120000 ms = 2 minutes
    
//         // Cleanup interval when component unmounts
//         return () => clearInterval(intervalId);
//       }, []);
    

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//   };

//   return (
//     <>
//       <Popup
//         modal
//         nested
//         open={isModalOpen}
//         onClose={closeModal}
//         closeOnDocumentClick={false}
//         contentStyle={{ width: popupWidth }}
//       >
//         <div className="popup-container" ref={popupContainerRef} id={popupId}>
//           <div className="closePopupButton" onClick={closeModal}>
//             <IoMdCloseCircle />
//           </div>
//           <div className="popup-display">{children}</div>
//           <div className="popup-button">
//             {submitButton}
//             <Button variant="contained" color="primary" onClick={closeModal}>
//               Close
//             </Button>
//           </div>
//         </div>
//       </Popup>
//     </>
//   );
// };

// export default PopupModel;


//manuval open

// import React, { useState, useEffect, useRef } from "react";
// import { IoMdCloseCircle } from "react-icons/io";
// import Popup from "reactjs-popup";
// import Button from "@mui/material/Button";

// const PopupModel = ({ children, submitButton }) => {
//   const [popupWidth, setPopupWidth] = useState("80%");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const popupContainerRef = useRef(null);

//   // Handle responsive popup width
//   useEffect(() => {
//     function handleResize() {
//       setPopupWidth(window.innerWidth <= 600 ? "90%" : "60%");
//     }
//     window.addEventListener("resize", handleResize);
//     handleResize();
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//   };

//   return (
//     <>
//       <Button
//         sx={{ lineHeight: 1.3 }}
//         variant="contained"
//         color="primary"
//         onClick={openModal}
//         className="signup-button"
//       >
//         Sign Up
//       </Button>
//       <Popup
//         modal
//         nested
//         open={isModalOpen}
//         onClose={closeModal}
//         closeOnDocumentClick={true}
//         contentStyle={{ width: popupWidth }}
//       >
//         <div className="popup-container" ref={popupContainerRef}>
//           <div className="closePopupButton" onClick={closeModal}>
//             <IoMdCloseCircle />
//           </div>
//           <div className="popup-display">{children}</div>
//           <div className="popup-button">
//             {submitButton}
//             <Button variant="contained" color="secondary" onClick={closeModal}>
//               Close
//             </Button>
//           </div>
//         </div>
//       </Popup>
//     </>
//   );
// };

// export default PopupModel;

// updated popupmodel working code ...................


import React, { useState, useEffect, useRef } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";

const PopupModel = ({ children, submitButton }) => {
  const [popupWidth, setPopupWidth] = useState("80%");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const popupContainerRef = useRef(null);


  // console.log('children' , children)
  // console.log('submitButton' , submitButton)

  // Handle responsive popup width
  useEffect(() => {
    function handleResize() {
      setPopupWidth(window.innerWidth <= 600 ? "90%" : "60%");
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Open the modal manually
  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  // // Automatically open the popup every 2 minutes
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setIsModalOpen(true);
  //     document.body.classList.add("modal-open");
  //   }, 120000); // 2 minutes in milliseconds

  //   return () => clearInterval(intervalId); // Clear interval on component unmount
  // }, []);

  return (
    <>
      {/* Button to manually open the popup */}
      <Button
        sx={{ lineHeight: 1.3 }}
        variant="contained"
        color="primary"
        onClick={openModal}
        className="signup-button"
      >
        Sign Up
      </Button>

      {/* Popup Component */}
      <Popup
        modal
        nested
        open={isModalOpen}
        onClose={closeModal}
        closeOnDocumentClick={true}
        contentStyle={{ width: popupWidth }}
      >
        <div className="popup-container" ref={popupContainerRef}>
          <div className="closePopupButton" onClick={closeModal}>
            <IoMdCloseCircle />
          </div>
          <div className="popup-display">{children}</div>
          <div className="popup-button">
            {submitButton}
            {/* <Button variant="contained" color="secondary" onClick={closeModal}>
              Close
            </Button> */}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default PopupModel;
