import React, { useEffect, useState } from "react";
import JobDetails from "./JobDetails";
import "../../CSS/JobPortal/JobPortal.css";
import { CiLock, CiLocationOn } from "react-icons/ci";
import Button from "@mui/material/Button";
import ReactModal from "react-modal";
import LayOut from "../Home/NavigationBar/LayOut";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { Helmet } from "react-helmet";

function JobportalIT() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedJob(null);
  };

  const PostTiming = (InitialPostedTime) => {
    const [elapsedTime, setElapsedTime] = useState("");

    useEffect(() => {
      const calculateElapsedTime = () => {
        const currentTime = new Date();
        const timeDifference = currentTime - InitialPostedTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
          setElapsedTime(`${days} ${days === 1 ? "day" : "days"} ago`);
        } else if (hours > 0) {
          setElapsedTime(`${hours} ${hours === 1 ? "hour" : "hours"} ago`);
        } else {
          setElapsedTime(`${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`);
        }
      };

      calculateElapsedTime();

      const intervalId = setInterval(() => {
        calculateElapsedTime();
      }, 60000);

      return () => clearInterval(intervalId);
    }, [InitialPostedTime]);

    return elapsedTime;
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    openModal();
  };

  // Filter job details based on the industry, in this case "IT"
  const filteredJobDetails = JobDetails.filter((job) =>
    job.jobDescription.some((desc) => desc.industry === "IT")
  );

  return (
    <div>
    <Helmet>
          <title>MyInception Tech - Find Top IT Jobs - IT Career Opportunities</title>
          <meta name="title" content="MyInception Tech - Find Top IT Jobs - IT Career Opportunities" />
          <meta name="description" content="Discover top IT job opportunities with MyInception Tech. We specialize in connecting skilled professionals with high-demand roles in software development, network engineering, Product Development Engineer, and more. Our career placement services and expert guidance will help you find the perfect IT job to accelerate your career. Whether you're looking for entry-level or senior positions, we provide personalized support for your success."/>
          <meta name="keywords" content=" myinceptiontech , inception , tech , inceptiontech , Internship , IT jobs , IT Job Vacancy , software development jobs , network engineering jobs , IT career opportunities , IT job placement , tech job openings , IT job search , IT roles , career in IT , telesales executive , web developer , On-premises devopsengineer , fullstack developer , ATE test Engineer "/>
          <meta name="robots" content=" index , follow , max-image-preview:large , max-snippet:-1"/>
          <meta name="google-site-verification" content="WHDj4Vlf9U0gHTojE3bGdbdE9lIaD-WEtGmVWsVtJP4" />
          <link rel="canonical" href="https://myinceptiontech.com/it-jobs"/>
          <meta property="og:title" content="MyInception Tech - Find Top IT Jobs - IT Career Opportunities"/>
          <meta property="og:description" content="Discover top IT job opportunities with MyInception Tech. We specialize in connecting skilled professionals with high-demand roles in software development, network engineering, Product Development Engineer, and more. Our career placement services and expert guidance will help you find the perfect IT job to accelerate your career. Whether you're looking for entry-level or senior positions, we provide personalized support for your success."/>
          <meta property="og:url" content="https://myinceptiontech.com/it-jobs"/>
          <meta property="og:type" content="website"/>
          <meta property="og:site_name" content="MyInception Tech: IT Career Opportunities" />
          <meta property="article:publisher" content="https://www.facebook.com/myinceptiontech" />
          <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Website",
            "name": "MyInception Tech - Find Top IT Jobs - IT Career Opportunities",
            "description": "Discover top IT job opportunities with MyInception Tech. We specialize in connecting skilled professionals with high-demand roles in software development, network engineering, Product Development Engineer, and more. Our career placement services and expert guidance will help you find the perfect IT job to accelerate your career. Whether you're looking for entry-level or senior positions, we provide personalized support for your success.",
            "url": "https://myinceptiontech.com/it-jobs",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.myinceptiontech.in/?s={search_term}",
              "query-input": "required name=search_term",
              },
          }
          `}
          </script>
          {/* Google Tag Manager (GTM) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GXE5MZYZZM');
          `}
        </script>
        </Helmet>
    <LayOut>
      <div className="jobPortal">
        {filteredJobDetails.map((information, index) => (
          <div key={index} className="searchJob">
            <div>
              <div className="designation">{information.designation}</div>
              <div className="company">{information.company}</div>
              <div className="work_details">
                <span>
                  <span>
                    <span className="lock-location-icon"><CiLock /></span> {information.experience}
                  </span>
                  <span className="separator1">|</span>
                  <span>
                    <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
                  </span>
                </span>
              </div>
            </div>
            <div className="vacancy">
              <span>
                <span>Vacancies: {information.vacancy}</span>
                <span className="separator">|</span>
                <span>{PostTiming(new Date(information.postedOn))}</span>
              </span>
            </div>
            <div className="handleJobClick">
              <Button
                variant="contained"
                onClick={() => handleJobClick(information)}
              >
                Apply Here
              </Button>
            </div>
          </div>
        ))}

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Course Details Modal"
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              transform: "translate(0%, 7%)",
            },
          }}
        >
          {selectedJob && (
            <div style={{ maxWidth: "90vw", overflow: "auto" }}>
              <div className="closePopupButton" onClick={closeModal}>
                <IoMdCloseCircle />
              </div>
              <div>
                <h2>{selectedJob.designation}</h2>
                {selectedJob.jobDescription.map((job, index) => (
                  <div key={index}>
                    {job.industry && (
                      <p>
                        <h3>Industry:</h3>
                        {job.industry}
                      </p>
                    )}

                    {job.responsibilities && (
                      <>
                        <h3>Responsibilities</h3>
                        <ul>
                          {job.responsibilities.map((responsibility, idx) => (
                            <li key={idx}>{responsibility}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.requirements && (
                      <>
                        <h3>Requirements</h3>
                        <ul>
                          {job.requirements.map((requirement, idx) => (
                            <li key={idx}>{requirement}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.recommendedSkills && (
                      <>
                        <h3>Recommended Skills</h3>
                        <ul>
                          {job.recommendedSkills.map((skill, idx) => (
                            <li key={idx}>{skill}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                ))}

                <div className="apply-close-btn">
                  <Link to="/redirect/mail" target="_blank">
                    <div>
                      <Button variant="contained">Apply</Button>
                    </div>
                  </Link>
                  <div>
                    <Button variant="contained" onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    </LayOut>
    </div>
  );
}

export default JobportalIT;
