import React from 'react';
import Image2 from '../Images/PATH/11.png'
import Image3 from '../Images/PATH/24.png'
import Image4 from '../Images/PATH/12.png'
import Image5 from '../Images/PATH/13.png'
import Image6 from '../Images/PATH/14.png'
import Image7 from '../Images/PATH/15.png'
import Image8 from '../Images/PATH/16.png'
import Image9 from '../Images/PATH/17.png'
import Image10 from '../Images/PATH/18.png'
import Image11 from '../Images/PATH/19.png'
import Image12 from '../Images/PATH/20.png'
import Image13 from '../Images/PATH/21.png'
import Image14 from '../Images/PATH/22.png'
import Image15 from '../Images/PATH/23.png'
import Image16 from '../Images/PATH/24.png'
import Image17 from '../Images/PATH/Dr.mahalingam Group Of Institution.png'
import Image19 from '../Images/PATH/EGSpillai Engineering College.png'
import Image20 from '../Images/PATH/Karpagam Engineering College.png'
import Image21 from '../Images/PATH/Loyola Institute of Technology.png'
import Image22 from '../Images/PATH/MAllareddi Group Of Institution.png'
import Image23 from '../Images/PATH/Rajadhani Institute.png'
import Image24 from '../Images/PATH/Sona college of technology.png'
import Image25 from '../Images/PATH/Syed Ammal Engineering College.png'
import '../CSS/Marquee/Marquee.css'


const Marquee = () => {
  return (
    <div className="marquee">
      <h2 className='contactus'> Our Clients</h2>
      <div className="marquee-content">
        <img src={Image2} alt="Logo 2" />
        <img src={Image3} alt="Logo 3" />
        <img src={Image4} alt="Logo 4" />
        <img src={Image5} alt="Logo 5" />
        <img src={Image6} alt="Logo 6" />
        <img src={Image7} alt="Logo 7" />
        <img src={Image8} alt="Logo 8" />
        <img src={Image9} alt="Logo 9" />
        <img src={Image10} alt="Logo 10" />
        <img src={Image11} alt="Logo 11" />
        <img src={Image12} alt="Logo 12" />
        <img src={Image13} alt="Logo 13" />
        <img src={Image14} alt="Logo 14" />
        <img src={Image15} alt="Logo 15" />
        <img src={Image16} alt="Logo 16" />
        <img src={Image17} alt="Dr.mahalingam Group Of Institution" />
        <img src={Image19} alt="EGSpillai Engineering College" />
        <img src={Image20} alt="Karpagam Engineering College" />
        <img src={Image21} alt="Loyola Institute of Technology" />
        <img src={Image22} alt="Maiiareddi Group Of Institution" />
        <img src={Image23} alt="Rajadhani Institute" />
        <img src={Image24} alt="Sona college of technology" />
        <img src={Image25} alt="Syed Ammal Engineering College" />
 
        
      </div>
    </div>
  );
};

export default Marquee;
