import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Courses = () => {
    return (
      <div>
        <Helmet>
          <title>Courses | IT & Non-IT Training Programs at MyInception Tech</title>
          <meta name="title" content="Courses | IT & Non-IT Training Programs at MyInception Tech" />
          <meta name="description" content="Explore a wide range of professional courses at MyInception Tech, including IT and non-IT training programs designed to enhance your skills and career prospects. From software development and HR training to management and core industry courses, our expert-led programs provide the knowledge and certification you need for success. Enroll today and take advantage of our comprehensive career development and job placement services."/>
          <meta name="keywords" content=" IT courses , non-IT courses , professional training , software development courses , HR training, management courses , career development programs , technology training , core industry training , skill enhancement , certification programs , online courses , job placement assistance , professional courses "/>
          <meta name="robots" content="index,follow"/>
          <link rel="canonical" href="https://myinceptiontech.com/courses/it"/>
          <meta property="og:title" content="Courses | IT & Non-IT Training Programs at MyInception Tech"/>
          <meta property="og:description" content="Explore a wide range of professional courses at MyInception Tech, including IT and non-IT training programs designed to enhance your skills and career prospects. From software development and HR training to management and core industry courses, our expert-led programs provide the knowledge and certification you need for success. Enroll today and take advantage of our comprehensive career development and job placement services."/>
          <meta property="og:url" content="https://myinceptiontech.com/courses/it"/>
          <meta property="og:type" content="website"/>
          <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Courses | IT & Non-IT Training Programs at MyInception Tech",
              "description": "Explore a wide range of professional courses at MyInception Tech, including IT and non-IT training programs designed to enhance your skills and career prospects. From software development and HR training to management and core industry courses, our expert-led programs provide the knowledge and certification you need for success. Enroll today and take advantage of our comprehensive career development and job placement services.",
              "url": "https://myinceptiontech.com/courses/it"
              "keywords":"IT courses , non-IT courses , professional training , software development courses , HR training, management courses , career development programs , technology training , core industry training , skill enhancement , certification programs , online courses , job placement assistance , professional courses",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.myinceptiontech.in/?s={IT courses , non-IT courses , professional training , software development courses , HR training, management courses , career development programs , technology training , core industry training , skill enhancement , certification programs , online courses , job placement assistance , professional courses}",
              "query-input": {
              "@type": "PropertyValueSpecification",
              "valueRequired": true,
              "valueName": "IT courses , non-IT courses , professional training , software development courses , HR training, management courses , career development programs , technology training , core industry training , skill enhancement , certification programs , online courses , job placement assistance , professional courses"
                }
              }
            }
            }
          `}
          </script>
          {/* Google Tag Manager (GTM) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GXE5MZYZZM');
          `}
        </script>
        </Helmet>

      <div className="dropdown-menu"> 
              <ul className='dropdown-list'>
                <li className='courses-topics'><Link to="/courses/it">IT</Link></li>
                <li className='courses-topics'><Link to="/courses/non-it">Non-IT</Link></li>
                <li className='courses-topics'><Link to="/courses/core">Core</Link></li>
              </ul>
              </div>
              </div>
    );
  };
export default Courses

