import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { RxHamburgerMenu } from "react-icons/rx";
import Courses from "./Courses";
import "../../../CSS/HomeSlider/Navbar.css";
import PopupForm from "../Signup/PopupForm";
import PopupModel from "../Signup/PopupModel";
import Button from "@mui/material/Button";

function NavigationBar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className="NavigationBar">
      <Logo />

      <button
        className="hamburger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <RxHamburgerMenu />
      </button>
      <div
        className={isNavExpanded ? "Nav-components expanded" : "Nav-components"}
      >
        <ul>
          <li className="NavBar-list">
            <Link to="/">Home</Link>
          </li>
          <li className="NavBar-list">
            <Link to="/about">About Us</Link>
          </li>
          <li id="List" className="NavBar-list">
            <Courses />
          </li>
          <li className="NavBar-list">
            <Link to="/services"> Services </Link>
          </li>

          {/* <ul > */}
            <li className="NavBar-list">
              <PopupModel
                submitButton={
                  <Button
                    type="submit"
                    form="popupForm"
                    className="popupform-submit-button"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                }
              >
                <PopupForm />
              </PopupModel>
            </li>
          </ul>
        {/* </ul> */}
      </div>
    </div>
  );
}
export default NavigationBar;
















// function NavigationBar() {
//   const [isNavExpanded, setIsNavExpanded] = useState(false);
//   const [showForm, setShowForm] = useState(false); // Manage the form visibility

//   return (
//     <div className="NavigationBar">
//       <Logo />
//       <button className="hamburger" onClick={() => setIsNavExpanded(!isNavExpanded)}>
//         <RxHamburgerMenu />
//       </button>
//       <div className={isNavExpanded ? 'Nav-components expanded' : 'Nav-components'}>
//         <ul>
//           <li className="NavBar-list">
//             <Link to="/">Home</Link>
//           </li>
//           <li className="NavBar-list">
//             <Link to="/about">About Us</Link>
//           </li>
//           <li id="List" className="NavBar-list">
//             <Courses />
//           </li>
//           <li className="NavBar-list">
//             <Link to="/services">Services</Link>
//           </li>
//           <li className="NavBar-list">
//             <button className="nav-signup-button"
//             onClick={() => setShowForm(true)}
//             >Signup</button>
//           </li>
//         </ul>
//       </div>
//       {showForm && <UserForm setShowForm={setShowForm} />}
//     </div>
//   );
// }

// export default NavigationBar;
