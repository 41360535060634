import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "../../../CSS/HomeSlider/animate.css";

export default function Animate() {
  const [counterOn, setCounterOn] = useState(false);
  const [registerMembers, setRegisterMembers] = useState(0);
  const [trainingMembers, setTrainingMembers] = useState(0);
  const [noOfCandidates, setNoOfCandidates] = useState(0);
  const [noOfClients, setNoOfClients] = useState(0);

  // Final values
  const finalRegisterMembers = 153836;
  const finalTrainingMembers = 113517;
  const finalNoOfCandidates = 53954;
  const finalNoOfClients = 79;

  useEffect(() => {
    // Load values from local storage when the component mounts
    const registerMembersValue = parseInt(localStorage.getItem("registerMembers")) || 0;
    const trainingMembersValue = parseInt(localStorage.getItem("trainingMembers")) || 0;
    const noOfCandidatesValue = parseInt(localStorage.getItem("noOfCandidates")) || 0;
    const noOfClientsValue = parseInt(localStorage.getItem("noOfClients")) || 0;

    setRegisterMembers(registerMembersValue);
    setTrainingMembers(trainingMembersValue);
    setNoOfCandidates(noOfCandidatesValue);
    setNoOfClients(noOfClientsValue);
  }, []);

  // Function to handle scroll enter and exit
  // const handleScroll = (visible) => {
  //   setCounterOn(visible);
  // };

  return (
    <ScrollTrigger
    onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)} >
      <div className="animatestyle">
        <div className="scroll1">
          <div className="counts" id="register">
            <div className="first-count">
              <div className="first">
                {counterOn && (
                  <CountUp
                    start={0}
                    end={finalRegisterMembers || registerMembers}
                    duration={2}
                    delay={0}
                  />
                )}
                <span> + </span>
              </div>
              <div className="first">Register Members</div>
              
              {/* <div className="server-value">{registerMembers}</div> */}
            </div>
          </div>
          <div className="counts" id="training">
            <div className="second-count">
              <div className="second">
                {counterOn && (
                  <CountUp
                    start={0}
                    end={finalTrainingMembers || trainingMembers}
                    duration={2}
                    delay={0}
                  />
                )}
                <span> + </span>
              </div>
              <div className="second">Trained Members</div>
            </div>
          </div>
          <div className="counts" id="placement">
            <div className="third-count">
              <div className="third">
                {counterOn && (
                  <CountUp
                    start={0}
                    end={finalNoOfCandidates || noOfCandidates}
                    duration={2}
                    delay={0}
                  />
                )}
                <span> + </span>
              </div>
              <div className="third">Placements</div>
            </div>
          </div>
          <div className="counts" id="clients">
            <div className="forth-count">
              <div className="forth">
                {counterOn && (
                  <CountUp start={0} end={finalNoOfClients || noOfClients } duration={2} delay={0} />
                )}
                <span> + </span>
              </div>
              <div className="forth"> No of Clients</div>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}

// import React, { useState } from "react";
// import CountUp from "react-countup";
// import ScrollTrigger from "react-scroll-trigger";
// import "../../../CSS/HomeSlider/animate.css";

// export default function Animate() {
//   const [countterOn, setCounterOn] = useState(false);
//   var registerMembers = 153236; // resp.val
//   var trainingMembers = 113217;
//   var noOfCandidates = 53454;
//   var noOfClients = 75;

  
//   return (
//     <ScrollTrigger
//       onEnter={() => setCounterOn(true)}
//       onExit={() => setCounterOn(false)}
//     >
//       <div className="animatestyle">
//         <div className="scroll1">
//           <div className="counts" id="register">
//             <div className="first-count">
//               <div className="first">
//                 {countterOn && (
//                   <CountUp
//                     start={0}
//                     end={registerMembers}
//                     duration={2}
//                     delay={0}
//                   />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="first">Register Members</div>
//             </div>
//           </div>
//           <div className="counts" id="training">
//             <div className="second-count">
//               <div className="second">
//                 {countterOn && (
//                   <CountUp
//                     start={0}
//                     end={trainingMembers}
//                     duration={2}
//                     delay={0}
//                   />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="second">Trained Members</div>
//             </div>
//           </div>

//           <div className="counts" id="placement">
//             <div className="third-count">
//               <div className="third">
//                 {countterOn && (
//                   <CountUp
//                     start={0}
//                     end={noOfCandidates}
//                     duration={2}
//                     delay={0}
//                   />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="third">Placements</div>
//             </div>
            
//           </div>
//           <div className="counts" id="clients">
//             <div className="forth-count">
//               <div className="forth">
//                 {countterOn && (
//                   <CountUp start={0} end={noOfClients} duration={2} delay={0} />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="forth"> No of Clients</div>
//             </div>
//           </div>

     
//         </div>
//       </div>
//     </ScrollTrigger>
//   );
// }



// import React, { useState, useEffect } from "react";
// import CountUp from "react-countup";
// import ScrollTrigger from "react-scroll-trigger";
// import "../../../CSS/HomeSlider/animate.css";

// export default function Animate() {
//   const [counterOn, setCounterOn] = useState(false);
//   const [registerMembers, setRegisterMembers] = useState(153236);
//   const [trainingMembers, setTrainingMembers] = useState(113217);
//   const [noOfCandidates, setNoOfCandidates] = useState(53454);
//   const [noOfClients, setNoOfClients] = useState(75);

//   // const registerMembersValue = 153236; // resp.val
//   //   const trainingMembersValue = 113217;
//   //   const noOfCandidatesValue = 53454;
//   //   const noOfClientsValue = 75;

//   useEffect(() => {
//     // Load values from local storage when the component mounts
//     const registerMembersValue = parseInt(localStorage.getItem("registerMembers")) || 0;
//     const trainingMembersValue = parseInt(localStorage.getItem("trainingMembers")) || 0;
//     const noOfCandidatesValue = parseInt(localStorage.getItem("noOfCandidates")) || 0;
//     const noOfClientsValue = parseInt(localStorage.getItem("noOfClients")) || 0;

//     setRegisterMembers(registerMembersValue);
//     setTrainingMembers(trainingMembersValue);
//     setNoOfCandidates(noOfCandidatesValue);
//     setNoOfClients(noOfClientsValue);

//     // Event listener for scroll events
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setCounterOn(true); // Set counterOn to true if the page is scrolled down
//       } else {
//         setCounterOn(false); // Set counterOn to false if the page is scrolled up to the top
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     // Cleanup function to remove the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     // Update values in local storage whenever the state changes
//     localStorage.setItem("registerMembers", registerMembers.toString());
//     localStorage.setItem("trainingMembers", trainingMembers.toString());
//     localStorage.setItem("noOfCandidates", noOfCandidates.toString());
//     localStorage.setItem("noOfClients", noOfClients.toString());
//   }, [registerMembers, trainingMembers, noOfCandidates, noOfClients]);

//   return (
//     <div className="animatestyle">
//       <div className="scroll1">
//         <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
//           <div className="counts" id="register">
//             <div className="first-count">
//               <div className="first">
//                 {counterOn && (
//                   <CountUp start={0} end={registerMembers} duration={2} delay={0} />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="first">Register Members</div>
//             </div>
//           </div>
//           <div className="counts" id="training">
//             <div className="second-count">
//               <div className="second">
//                 {counterOn && (
//                   <CountUp start={0} end={trainingMembers} duration={2} delay={0} />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="second">Trained Members</div>
//             </div>
//           </div>
//           <div className="counts" id="placement">
//             <div className="third-count">
//               <div className="third">
//                 {counterOn && (
//                   <CountUp start={0} end={noOfCandidates} duration={2} delay={0} />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="third">Placements</div>
//             </div>
//           </div>
//           <div className="counts" id="clients">
//             <div className="forth-count">
//               <div className="forth">
//                 {counterOn && (
//                   <CountUp start={0} end={noOfClients} duration={2} delay={0} />
//                 )}
//                 <span> + </span>
//               </div>
//               <div className="forth">No of Clients</div>
//             </div>
//           </div>
//         </ScrollTrigger>
//       </div>
//     </div>
//   );
// }
