
import React, { useState } from 'react';
import { CoreDetails } from './CoreDetails'; 
import ReactModal from 'react-modal';
import '../../../CSS/Courses/Courses.css';
import LayOut from '../../Home/NavigationBar/LayOut';
import Button from '@mui/material/Button';
import { IoMdCloseCircle } from "react-icons/io";
import { Helmet } from "react-helmet";

const CoreComponent = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal=() =>{
    setIsModalOpen(true);
    document.body.classList.add("modal-open")
  }
  const closeModal=()=>{
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedCourse(null)

  }

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    // setIsModalOpen(true);
    openModal();
  };
  
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <div>
    <Helmet>
          <title>MyInception Tech | Core Courses | Embedded Systems, IoT, PLC/SCADA Training</title>
          <meta name="title" content="MyInception Tech | Core Courses | Embedded Systems, IoT, PLC/SCADA Training" />
          <meta name="description" content="Advance your career with core courses at MyInception Tech, including Embedded Systems, Internet of Things (IoT), LT Panel Design, HT Panel Design, and PLC/SCADA. Our hands-on training programs equip you with the technical skills required for success in engineering and industrial automation."/>
          <meta name="keywords" content="Embedded Systems course , IoT training , LT Panel Design course , HT Panel Design course , PLC training , SCADA training , industrial automation courses , core engineering courses , technical skills training"/>
          <meta name="robots" content=" index , follow , max-image-preview:large , max-snippet:-1"/>
          <meta name="google-site-verification" content="WHDj4Vlf9U0gHTojE3bGdbdE9lIaD-WEtGmVWsVtJP4" />
          <link rel="canonical" href="https://myinceptiontech.com/courses/core" />
          <meta property="og:title" content="MyInception Tech | Core Courses | Embedded Systems, IoT, PLC/SCADA Training"/>
          <meta property="og:description" content="Advance your career with core courses at MyInception Tech, including Embedded Systems, Internet of Things (IoT), LT Panel Design, HT Panel Design, and PLC/SCADA. Our hands-on training programs equip you with the technical skills required for success in engineering and industrial automation."/>
          <meta property="og:url" content="https://myinceptiontech.com/courses/core"/>
          <meta property="og:type" content="website"/>
          <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "MyInception Tech | Core Courses | Embedded Systems, IoT, PLC/SCADA Training",
              "description": "Advance your career with core courses at MyInception Tech, including Embedded Systems, Internet of Things (IoT), LT Panel Design, HT Panel Design, and PLC/SCADA. Our hands-on training programs equip you with the technical skills required for success in engineering and industrial automation.",
              "url": "https://myinceptiontech.com/courses/core",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.myinceptiontech.in/?s={search_term}",
              "query-input":  "required name=search_term",
              },
            }
          `}
          </script>
          {/* Google Tag Manager (GTM) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GXE5MZYZZM');
          `}
        </script>
        </Helmet>
    <LayOut> 
    <div>
      <h2 className='courses-heading'>Core</h2>
      <div className='course-container'>
      {CoreDetails.map(course => (
        <div key={course.id} >
        
          <img src={course.image} alt={course.heading} onClick={() => handleCourseClick(course)}/>
          <h3 onClick={() => handleCourseClick(course)}>{course.heading}</h3>
        
        </div>
      ))}
</div>
      <ReactModal 
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Course Details Modal"
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            transform: "translate(0% ,5%)",
          },
          maxWidth : "80vw", 
          
        }}
      >
        {selectedCourse && (
          <div style={{maxWidth: '75vw', overflow:"auto"}}>
            <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
            <h2 className='popup-Title'>{selectedCourse.heading}</h2>
            <p>{selectedCourse.object}</p>
            <h3>Course Modules:</h3>
            <ol>
              {selectedCourse.coursesModules.map(module => (
                <li key={module.id}>
                  {module.subHeading && <h2>{module.subHeading}</h2>}
                  <h4>{module.title}</h4>
                  <ul>
                    {module.description && module.description.map((desc, index) => (
                      <li key={index}>
                        <p>{desc.subTitle}</p>
                        
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
            {/* <p>Fees: {selectedCourse.details[0].fee}</p> */}
            <p>Duration: {selectedCourse.details[0].duration}</p>
            <p>Enroll Now:{selectedCourse.enrollNow}</p>
            <div className="courseClose">
            <Button variant="contained" color="primary" onClick={closeModal} > Close </Button>
            </div>
            {/* <Button variant="contained" color="primary" onClick={closeModal} > Close </Button> */}
          </div>
        )}
      </ReactModal>
    </div>
    </LayOut>
    </div>
  );
};

export default CoreComponent;

