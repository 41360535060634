// import React from 'react';
// import '../../CSS/Email.css';
// import { Helmet } from "react-helmet";



// function EmailSending() {
  
//   return (
//     <div>
//         <Helmet>
//       <title>Apply for Jobs | IT & Non-IT Career Opportunities - MyInception Tech</title>
//           <meta name="description" content="Looking for a job in IT, Non-IT, or core industries? Apply now through MyInception Tech's recruitment portal. We offer exciting career opportunities in fields like software development, HR, digital marketing, data science, and more. Submit your resume today and take the next step toward a successful career!"/>
//           <meta name="keywords" content=" apply for jobs, IT jobs, non-IT jobs, software development jobs, HR jobs, data science jobs, career opportunities, job application, submit resume, MyInception Tech recruitment, tech jobs, core industry jobs"/>
//           <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1"/>
//           <link rel="canonical" href="https://myinceptiontech.com/redirect/mail" />
//           <meta property="og:title" content="Apply for Jobs | IT & Non-IT Career Opportunities - MyInception Tech" />
//           <meta property="og:description" content="Looking for a job in IT, Non-IT, or core industries? Apply now through MyInception Tech's recruitment portal. We offer exciting career opportunities in fields like software development, HR, digital marketing, data science, and more. Submit your resume today and take the next step toward a successful career!"/>
//           <meta property="og:image" content="https://myinceptiontech.com/static/media/24.56ac5a7bd8ab20a702ba.png"/>
//           <meta property="og:url" content="https://myinceptiontech.com/redirect/mail"/>
//           <meta property="og:type" content="website"/>
//           <meta property="og:site_name" content="MyInception Tech: Training and Placement Consultancy" />
//           <meta property="article:publisher" content="https://www.facebook.com/myinceptiontech" />
//           <script type="application/ld+json">
//           {`
//             {
//               "@context": "https://schema.org",
//               "@type": "WebSite",
//               "url": "https://myinceptiontech.com/redirect/mail",
//               "sameAs": [
//               "https://www.https://www.instagram.com/myinception_tech/",
//               "https://www.linkedin.com/in/myinception-tech-288422269/",
//               "https://www.google.com/maps/place/MyInception+Tech/@13.0346222,80.2272874,19.17z/data=!4m6!3m5!1s0x3a5267660b0708a5:0x1f55b129324be639!8m2!3d13.0345143!4d80.2276413!16s%2Fg%2F11twzkp_bv?hl=en&entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D",
//               "name": "Apply for Jobs | IT & Non-IT Career Opportunities - MyInception Tech",
//               "description": "Looking for a job in IT, Non-IT, or core industries? Apply now through MyInception Tech's recruitment portal. We offer exciting career opportunities in fields like software development, HR, digital marketing, data science, and more. Submit your resume today and take the next step toward a successful career!",
//               "keywords":"apply for jobs, IT jobs, non-IT jobs, software development jobs, HR jobs, data science jobs, career opportunities, job application, submit resume, MyInception Tech recruitment, tech jobs, core industry jobs",
//               "potentialAction": {
//               "@type": "SearchAction",
//               "target": "https://www.myinceptiontech.in/?s={apply for jobs, IT jobs, non-IT jobs, software development jobs, HR jobs, data science jobs, career opportunities, job application, submit resume, MyInception Tech recruitment, tech jobs, core industry jobs}",
//               "query-input": {
//               "@type": "PropertyValueSpecification",
//               "valueRequired": true,
//               "valueName": "apply for jobs, IT jobs, non-IT jobs, software development jobs, HR jobs, data science jobs, career opportunities, job application, submit resume, MyInception Tech recruitment, tech jobs, core industry jobs"
//                 }
//               }
//             }
//           `}
//           </script>
//           {/* Google Tag Manager (GTM) */}
//         <script async src="https://www.googletagmanager.com/gtag/js?id=G-GXE5MZYZZM"></script>
//         <script>
//           {`
//             window.dataLayer = window.dataLayer || [];
//             function gtag(){dataLayer.push(arguments);}
//             gtag('js', new Date());
//             gtag('config', 'G-GXE5MZYZZM');
//           `}
//         </script>
//       </Helmet>
//     <form className='email-sending'
//     action="https://formspree.io/f/xldelbjj"
//   // action="https://formspree.io/f/xdoqwoyg"
//   method="POST"
// >
// <h2 className='email-head'>Apply for a Job</h2>
//   <div className="email-container">
//   <label className='email-label'>Name </label>
//   <input type="text" name="user_name" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Phone </label>
//   <input type="tel" name="user_phone" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Email </label>
//   <input type="email" name="user_email" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Degree </label>      
//       <select name='user_degree' className='input-box' required>
//         <option></option>
//         <option>B.E</option>
//         <option>B.Tech</option>
//         <option>B.sc</option>
//         <option>B.com</option>
//       </select>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Specialization </label>
//   <input type="text" name='user_specialization' required className='input-box' placeholder='e.g CSE'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Experience  </label>
//   <input type='number' name="user_Experience" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Upload Resume  </label>
//   <input type="file" name="user_file" required className='input-box'/>
//   </div>
//   <div className="email-container"> 
//   <button type="submit" className="submit-button">
//           Send
//         </button>
//   </div>
 

  
   

     
  

//     </form>
//     </div>
//   );
// }

// export default EmailSending;





// import React from 'react';
// import '../../CSS/Email.css';



// function EmailSending() {
  
//   return (
//     <div>
//     <form
//     className='email-sending'
//     action="https://formspree.io/f/xldelbjj"
//     method="POST"
// >
// <h2 className='email-head'>Apply for a Job</h2>
//   <div className="email-container">
//   <label className='email-label'>Name </label>
//   <input type="text" name="user_name" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Phone </label>
//   <input type="tel" name="user_phone" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Email </label>
//   <input type="email" name="user_email" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Degree </label>      
//       <select name='user_degree' className='input-box' required>
//         <option></option>
//         <option>B.E</option>
//         <option>B.Tech</option>
//         <option>B.sc</option>
//         <option>B.com</option>
//       </select>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Specialization </label>
//   <input type="text" name='user_specialization' required className='input-box' placeholder='e.g CSE'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Experience  </label>
//   <input type='number' name="user_Experience" required className='input-box'/>
//   </div>
//   <div className="email-container">
//   <label className='email-label'>Upload Resume  </label>
//   <input type="file" name="user_file" required className='input-box'/>
//   </div>
//   <div className="email-container"> 
//   <button type="submit" className="submit-button">
//           Send
//         </button>
//   </div>
//     </form>
//     </div>
//   );
// }

// export default EmailSending;


//working

// import React, { useState } from 'react';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [fileData, setFileData] = useState('');

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setFileData(reader.result); // File data as a Base64 string
//       };
//       reader.readAsDataURL(file); // Converts file to Base64
//     }
//   };

//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/xldelbjj"
//         method="POST"
//          encType="multipart/form-data"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input type="text" name="user_name" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input type="tel" name="user_phone" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input type="email" name="user_email" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Degree</label>
//           <select name="user_degree" className="input-box" required>
//             <option></option>
//             <option>B.E</option>
//             <option>B.Tech</option>
//             <option>B.sc</option>
//             <option>B.com</option>
//           </select>
//         </div>
//         <div className="email-container">
//           <label className="email-label">Specialization</label>
//           <input
//             type="text"
//             name="user_specialization"
//             required
//             className="input-box"
//             placeholder="e.g CSE"
//           />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Experience</label>
//           <input type="number" name="user_experience" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input type="file" className="input-box" onChange={handleFileChange} />
//           <input type="hidden" name="user_file_data" value={fileData} />
//         </div>
//         <div className="email-container">
//           <button type="submit" className="submit-button">
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;



///working without file

// import React, { useState } from 'react';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     specialization: '',
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     switch (name) {
//       case 'name':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           name: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Name must contain only letters.',
//         }));
//         break;
//       case 'phone':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           phone: /^[0-9]{10}$/.test(value) ? '' : 'Phone number must be 10 digits.',
//         }));
//         break;
//       case 'email':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email format.',
//         }));
//         break;
//       case 'specialization':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           specialization: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Specialization must contain only letters.',
//         }));
//         break;
//       default:
//         break;
//     }
//   };

//   const [fileData, setFileData] = useState('');


//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setFileData(reader.result); // Base64 encoded file content
//       };
//       reader.readAsDataURL(file); // Converts the file to Base64
//     }
//   };

//   return (
//     <div>
//       <form
//         className="email-sending"
//          action="https://formspree.io/f/mqakllpd"
//         method="POST"
//          encType="multipart/form-data"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className={`input-box ${errors.name ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.name && <span className="error-message1">{errors.name}</span>}
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input
//             type="tel"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             className={`input-box ${errors.phone ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.phone && <span className="error-message1">{errors.phone}</span>}
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className={`input-box ${errors.email ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.email && <span className="error-message1">{errors.email}</span>}
//         <div className="email-container">
//           <label className="email-label">Degree</label>
//           <select name="user_degree" className="input-box" required>
//             <option value="">Select</option>
//             <option value="B.E">B.E</option>
//             <option value="B.Tech">B.Tech</option>
//             <option value="B.Sc">B.Sc</option>
//             <option value="B.Com">B.Com</option>
//           </select>
//         </div>
//         <div className="email-container">
//           <label className="email-label">Specialization</label>
//           <input
//             type="text"
//             name="specialization"
//             value={formData.specialization}
//             onChange={handleChange}
//             className={`input-box ${errors.specialization ? 'error1' : ''}`}
//             placeholder="e.g. CSE"
//             required
//           />
//         </div>
//         {errors.specialization && <span className="error-message1">{errors.specialization}</span>}
//         <div className="email-container">
//           <label className="email-label">Experience</label>
//           <input type="number" name="user_experience" className="input-box" required />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input type="file" className="input-box" onChange={handleFileChange} />
//           <input type="hidden" name="user_file_data" value={fileData} />
//         </div>
//         <div className="email-container">
//           <button type="submit" className="submit-button" disabled={Object.values(errors).some((error) => error)}>
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;




import React, { useState } from 'react';
import axios from 'axios';
import '../../CSS/Email.css';

function EmailSending() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    specialization: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    switch (name) {
      case 'name':
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Name must contain only letters.',
        }));
        break;
      case 'phone':
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: /^[0-9]{10}$/.test(value) ? '' : 'Phone number must be 10 digits.',
        }));
        break;
      case 'email':
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email format.',
        }));
        break;
      case 'specialization':
        setErrors((prevErrors) => ({
          ...prevErrors,
          specialization: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Specialization must contain only letters.',
        }));
        break;
      default:
        break;
    }
  };

  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

    // Upload file to Cloudinary
    const uploadFileToCloudinary = async () => {
      if (!file) {
        alert('Please select a file to upload.');
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'react_upload_preset'); // Replace with your Cloudinary preset
  
      try {
        const response = await axios.post(
          // `https://api.cloudinary.com/v1_1/dzdlhgvlg/upload`, // Replace with your Cloudinary cloud name
          `https://api.cloudinary.com/v1_1/dcgazzejy/upload`, // Replace with your Cloudinary cloud name
          formData
        );
        setFileURL(response.data.secure_url);
        setIsFileUploaded(true);
        alert('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('File upload failed. Please try again.');
      }
    };


  return (
    <div>
      <form
        className="email-sending"
         action="https://formspree.io/f/mqakllpd"
        method="POST"
      >
        <h2 className="email-head">Apply for a Job</h2>
        <div className="email-container">
          <label className="email-label">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`input-box ${errors.name ? 'error1' : ''}`}
            required
          />
        </div>
        {errors.name && <span className="error-message1">{errors.name}</span>}
        <div className="email-container">
          <label className="email-label">Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`input-box ${errors.phone ? 'error1' : ''}`}
            required
          />
        </div>
        {errors.phone && <span className="error-message1">{errors.phone}</span>}
        <div className="email-container">
          <label className="email-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`input-box ${errors.email ? 'error1' : ''}`}
            required
          />
        </div>
        {errors.email && <span className="error-message1">{errors.email}</span>}
        <div className="email-container">
          <label className="email-label">Degree</label>
          <select name="user_degree" className="input-box" required>
            <option value="">Select</option>
            <option value="B.E">B.E</option>
            <option value="B.Tech">B.Tech</option>
            <option value="B.Sc">B.Sc</option>
            <option value="B.Com">B.Com</option>
          </select>
        </div>
        <div className="email-container">
          <label className="email-label">Specialization</label>
          <input
            type="text"
            name="specialization"
            value={formData.specialization}
            onChange={handleChange}
            className={`input-box ${errors.specialization ? 'error1' : ''}`}
            placeholder="e.g. CSE"
            required
          />
        </div>
        {errors.specialization && <span className="error-message1">{errors.specialization}</span>}
        <div className="email-container">
          <label className="email-label">Experience</label>
          <input type="number" name="user_experience" className="input-box" required />
        </div>
        <div className="email-container">
          <label className="email-label">Upload Resume</label>
          <input type="file"
          className="input-box upload-input" 
          name="user_file"
          onChange={handleFileChange}
          required
          />
           <button
            type="button"
            onClick={uploadFileToCloudinary}
            className="upload-button"
          >
            Upload File
          </button>
        </div>

        {isFileUploaded && (
          <div className="email-container">
            <label className="email-label">File Link</label>
            <input
              type="text"
              name="user_file_link"
              value={fileURL}
              readOnly
              className="input-box"
            />
          </div>
        )}
        <div className="email-container">
          <button type="submit"
           className="submit-button" 
           disabled={!isFileUploaded}
           >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmailSending;




// import React, { useState } from 'react';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     specialization: '',
//   });
//   const [errors, setErrors] = useState({});
//   const [fileData, setFileData] = useState('');

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     switch (name) {
//       case 'name':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           name: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Name must contain only letters.',
//         }));
//         break;
//       case 'phone':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           phone: /^[0-9]{10}$/.test(value) ? '' : 'Phone number must be 10 digits.',
//         }));
//         break;
//       case 'email':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email format.',
//         }));
//         break;
//       case 'specialization':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           specialization: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Specialization must contain only letters.',
//         }));
//         break;
//       default:
//         break;
//     }
//   };



//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/mqakllpd"
//         method="POST"
//         encType="multipart/form-data"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className={`input-box ${errors.name ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.name && <span className="error-message1">{errors.name}</span>}
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input
//             type="tel"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             className={`input-box ${errors.phone ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.phone && <span className="error-message1">{errors.phone}</span>}
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className={`input-box ${errors.email ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.email && <span className="error-message1">{errors.email}</span>}
//         <div className="email-container">
//           <label className="email-label">Degree</label>
//           <select name="user_degree" className="input-box" required>
//             <option value="">Select</option>
//             <option value="B.E">B.E</option>
//             <option value="B.Tech">B.Tech</option>
//             <option value="B.Sc">B.Sc</option>
//             <option value="B.Com">B.Com</option>
//           </select>
//         </div>
//         <div className="email-container">
//           <label className="email-label">Specialization</label>
//           <input
//             type="text"
//             name="specialization"
//             value={formData.specialization}
//             onChange={handleChange}
//             className={`input-box ${errors.specialization ? 'error1' : ''}`}
//             placeholder="e.g. CSE"
//             required
//           />
//         </div>
//         {errors.specialization && <span className="error-message1">{errors.specialization}</span>}
//         <div className="email-container">
//           <label className="email-label">Experience</label>
//           <input type="number" name="user_experience" className="input-box" required />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input type="file" className="input-box"  required />
//           {/* Hidden input to include the Base64 data */}
//           <input type="hidden" name="user_file_data" value={fileData} />
//         </div>
//         <div className="email-container">
//           <button type="submit" className="submit-button" disabled={Object.values(errors).some((error) => error)}>
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;


//without file

// import React, { useState } from 'react';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     specialization: '',
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     switch (name) {
//       case 'name':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           name: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Name must contain only letters.',
//         }));
//         break;
//       case 'phone':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           phone: /^[0-9]{10}$/.test(value) ? '' : 'Phone number must be 10 digits.',
//         }));
//         break;
//       case 'email':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email format.',
//         }));
//         break;
//       case 'specialization':
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           specialization: /^[a-zA-Z\s]+$/.test(value) ? '' : 'Specialization must contain only letters.',
//         }));
//         break;
//       default:
//         break;
//     }
//   };

//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/mqakllpd"
//         method="POST"
//         encType="multipart/form-data" // This is necessary for file uploads
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className={`input-box ${errors.name ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.name && <span className="error-message1">{errors.name}</span>}
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input
//             type="tel"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             className={`input-box ${errors.phone ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.phone && <span className="error-message1">{errors.phone}</span>}
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className={`input-box ${errors.email ? 'error1' : ''}`}
//             required
//           />
//         </div>
//         {errors.email && <span className="error-message1">{errors.email}</span>}
//         <div className="email-container">
//           <label className="email-label">Degree</label>
//           <select name="user_degree" className="input-box" required>
//             <option value="">Select</option>
//             <option value="B.E">B.E</option>
//             <option value="B.Tech">B.Tech</option>
//             <option value="B.Sc">B.Sc</option>
//             <option value="B.Com">B.Com</option>
//           </select>
//         </div>
//         <div className="email-container">
//           <label className="email-label">Specialization</label>
//           <input
//             type="text"
//             name="specialization"
//             value={formData.specialization}
//             onChange={handleChange}
//             className={`input-box ${errors.specialization ? 'error1' : ''}`}
//             placeholder="e.g. CSE"
//             required
//           />
//         </div>
//         {errors.specialization && <span className="error-message1">{errors.specialization}</span>}
//         <div className="email-container">
//           <label className="email-label">Experience</label>
//           <input type="number" name="user_experience" className="input-box" required />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input type="file" name="resume" className="input-box" required />
//         </div>
//         <div className="email-container">
//           <button type="submit" className="submit-button" disabled={Object.values(errors).some((error) => error)}>
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;



// import React, { useState } from 'react';
// import axios from 'axios';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [file, setFile] = useState(null);
//   const [fileURL, setFileURL] = useState('');
//   const [isFileUploaded, setIsFileUploaded] = useState(false);

//   // Handle file change
//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   // Upload file to Cloudinary
//   const uploadFileToCloudinary = async () => {
//     if (!file) {
//       alert('Please select a file to upload.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('upload_preset', 'react_upload_preset'); // Replace with your Cloudinary preset

//     try {
//       const response = await axios.post(
//         `https://api.cloudinary.com/v1_1/dzdlhgvlg/upload`, // Replace with your Cloudinary cloud name
//         formData
//       );
//       setFileURL(response.data.secure_url);
//       setIsFileUploaded(true);
//       alert('File uploaded successfully!');
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       alert('File upload failed. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/xldelbjj" 
//         method="POST"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input type="text" name="user_name" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input type="tel" name="user_phone" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input type="email" name="user_email" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input
//             type="file"
//             name="user_file"
//             required
//             className="input-box"
//             onChange={handleFileChange}
//           />
//           <button
//             type="button"
//             onClick={uploadFileToCloudinary}
//             className="upload-button"
//           >
//             Upload File
//           </button>
//         </div>

//         {isFileUploaded && (
//           <div className="email-container">
//             <label className="email-label">File Link</label>
//             <input
//               type="text"
//               name="user_file_link"
//               value={fileURL}
//               readOnly
//               className="input-box"
//             />
//           </div>
//         )}

//         <div className="email-container">
//           <button
//             type="submit"
//             className="submit-button"
//             disabled={!isFileUploaded}
//           >
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;



//cloudinary and formspree both use in gmail sending and working code 

// import React, { useState } from 'react';
// import axios from 'axios';
// import '../../CSS/Email.css';

// function EmailSending() {
//   const [file, setFile] = useState(null);
//   const [fileURL, setFileURL] = useState('');
//   const [isFileUploaded, setIsFileUploaded] = useState(false);

//   // Handle file change
//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   // Upload file to Cloudinary
//   const uploadFileToCloudinary = async () => {
//     if (!file) {
//       alert('Please select a file to upload.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('upload_preset', 'react_upload_preset'); // Replace with your Cloudinary preset

//     try {
//       const response = await axios.post(
//         `https://api.cloudinary.com/v1_1/dzdlhgvlg/upload`, // Replace with your Cloudinary cloud name
//         formData
//       );
//       setFileURL(response.data.secure_url);
//       setIsFileUploaded(true);
//       alert('File uploaded successfully!');
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       alert('File upload failed. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/mqakllpd" 
//         method="POST"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <div className="email-container">
//           <label className="email-label">Name</label>
//           <input type="text" name="user_name" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Phone</label>
//           <input type="tel" name="user_phone" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Email</label>
//           <input type="email" name="user_email" required className="input-box" />
//         </div>
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input
//             type="file"
//             name="user_file"
//             required
//             className="input-box"
//             onChange={handleFileChange}
//           />
//           <button
//             type="button"
//             onClick={uploadFileToCloudinary}
//             className="upload-button"
//           >
//             Upload File
//           </button>
//         </div>

//         {isFileUploaded && (
//           <div className="email-container">
//             <label className="email-label">File Link</label>
//             <input
//               type="text"
//               name="user_file_link"
//               value={fileURL}
//               readOnly
//               className="input-box"
//             />
//           </div>
//         )}

//         <div className="email-container">
//           <button
//             type="submit"
//             className="submit-button"
//             disabled={!isFileUploaded}
//           >
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;











//refactor code 

// import React, { useState } from 'react';
// import '../../CSS/Email.css';

// // Reusable Form Field Component
// const FormField = ({ label, type, name, options, placeholder, required, onChange }) => (
//   <div className="email-container">
//     <label className="email-label">{label}</label>
//     {type === 'select' ? (
//       <select name={name} className="input-box" required={required} onChange={onChange}>
//         <option value="">{placeholder || 'Select an option'}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option}>
//             {option}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         name={name}
//         className="input-box"
//         placeholder={placeholder}
//         required={required}
//         onChange={onChange}
//       />
//     )}
//   </div>
// );

// function EmailSending() {
//   const [fileData, setFileData] = useState('');

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => setFileData(reader.result); // Convert file to Base64 string
//       reader.readAsDataURL(file);
//     }
//   };

//   const degreeOptions = ['B.E', 'B.Tech', 'B.Sc', 'B.Com'];

//   return (
//     <div>
//       <form
//         className="email-sending"
//         action="https://formspree.io/f/xldelbjj"
//         method="POST"
//         encType="multipart/form-data"
//       >
//         <h2 className="email-head">Apply for a Job</h2>
//         <FormField label="Name" type="text" name="user_name" placeholder="Enter your name" required />
//         <FormField label="Phone" type="tel" name="user_phone" placeholder="Enter your phone number" required />
//         <FormField label="Email" type="email" name="user_email" placeholder="Enter your email address" required />
//         <FormField
//           label="Degree"
//           type="select"
//           name="user_degree"
//           options={degreeOptions}
//           placeholder="Select your degree"
//           required
//         />
//         <FormField
//           label="Specialization"
//           type="text"
//           name="user_specialization"
//           placeholder="e.g. CSE"
//           required
//         />
//         <FormField
//           label="Experience"
//           type="number"
//           name="user_experience"
//           placeholder="Enter your experience in years"
//           required
//         />
//         <div className="email-container">
//           <label className="email-label">Upload Resume</label>
//           <input type="file" className="input-box" onChange={handleFileChange} />
//           <input type="hidden" name="user_file_data" value={fileData} />
//         </div>
//         <div className="email-container">
//           <button type="submit" className="submit-button">
//             Send
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EmailSending;



















// import React from 'react';
// import '../../CSS/Email.css';

// function EmailSending() {
//   return (
//     <form
//       className="email-sending-form"
//       action="https://formspree.io/f/xdoqwoyg"
//       method="POST"
//     >
//       <label className="form-label">Name</label>
//       <input type="text" name="user_name" required className="form-input" />

//       <label className="form-label">Phone</label>
//       <input type="tel" name="user_phone" required className="form-input" />

//       <label className="form-label">Email</label>
//       <input type="email" name="user_email" required className="form-input" />

//       <label className="form-label">Degree</label>
//       <select name="user_degree" className="form-input" required>
//         <option value="">Select</option>
//         <option>B.E</option>
//         <option>B.Tech</option>
//         <option>B.Sc</option>
//         <option>B.Com</option>
//       </select>

//       <label className="form-label">Specialization</label>
//       <input
//         type="text"
//         name="user_specialization"
//         required
//         className="form-input"
//         placeholder="e.g. CSE"
//       />

//       <label className="form-label">Experience</label>
//       <input type="number" name="user_experience" required className="form-input" />

//       <label className="form-label">Upload Resume</label>
//       <input type="file" name="user_file" required className="form-input-file" />

//       <input type="submit" value="Send" className="form-submit-button" />
//     </form>
//   );
// }

// export default EmailSending;
