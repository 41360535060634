//working code 



// import React, { useState } from "react";
// import "../../../CSS/HomeSlider/Signup.css";

// const UserForm = ({ setShowForm }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     degree: "",
//   });
//   const [errors, setErrors] = useState({});

//   // Validation function
//   // const validate = (name, value) => {
//   //   let errorMessage = "";

//   //   if (name === "name") {
//   //     if (!value.trim()) {
//   //       errorMessage = "Name is required";
//   //     }
//   //   } else if (name === "phone") {
//   //     if (!value.trim()) {
//   //       errorMessage = "Phone number is required";
//   //     } else if (!/^\d{10}$/.test(value)) {
//   //       errorMessage = "Phone number must be 10 digits";
//   //     }
//   //   } else if (name === "email") {
//   //     if (!value.trim()) {
//   //       errorMessage = "Email is required";
//   //     } else if (!/\S+@\S+\.\S+/.test(value)) {
//   //       errorMessage = "Email is not valid";
//   //     }
//   //   } else if (name === "degree") {
//   //     if (!value.trim()) {
//   //       errorMessage = "Degree is required";
//   //     }
//   //   }

//   //   setErrors((prevErrors) => ({
//   //     ...prevErrors,
//   //     [name]: errorMessage,
//   //   }));
//   // };
//   const validate = (name, value) => {
//     let errorMessage = "";
  
//     // Validate Name (only letters)
//     if (name === "name") {
//       if (!value.trim()) {
//         errorMessage = "Name is required";
//       } else if (!/^[A-Za-z\s]+$/.test(value)) { // Only letters and spaces allowed
//         errorMessage = "Name should only contain letters";
//       }
//     }
  
//     // Validate Phone (only 10 digits)
//     else if (name === "phone") {
//       if (!value.trim()) {
//         errorMessage = "Phone number is required";
//       } else if (!/^\d{10}$/.test(value)) {
//         errorMessage = "Phone number must be 10 digits";
//       }
//     }
  
//     // Validate Email (correct email format)
//     else if (name === "email") {
//       if (!value.trim()) {
//         errorMessage = "Email is required";
//       } else if (!/\S+@\S+\.\S+/.test(value)) { // Simple email pattern check
//         errorMessage = "Email is not valid";
//       }
//     }
  
//     // Validate Degree (only letters and spaces)
//     else if (name === "degree") {
//       if (!value.trim()) {
//         errorMessage = "Degree is required";
//       } else if (!/^[A-Za-z\s]+$/.test(value)) { // Only letters and spaces allowed
//         errorMessage = "Degree should only contain letters";
//       }
//     }
  
//     // Set the error for the respective field
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: errorMessage,
//     }));
//   };

//   // Handle input change with validation
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validate(name, value); // Validate each field on input change
//   };

//   // Check if form is valid (no errors) before allowing submission
//   const isFormValid = Object.values(errors).every((error) => !error);

//   return (
//     <div>
//       <div className="popup-overlay">
//         <div className="popup-form">
//           <h2>Fill Your Details</h2>
//           <form
//             action="https://formspree.io/f/xdoqwoyg" // Formspree action URL
//             method="POST" // Form submission method
//           >
//             <div>
//               <label>Name:</label>
//               <input
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleInputChange}
//               />
//               {errors.name && <p className="error">{errors.name}</p>}
//             </div>
//             <div>
//               <label>Phone:</label>
//               <input
//                 type="text"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleInputChange}
//               />
//               {errors.phone && <p className="error">{errors.phone}</p>}
//             </div>
//             <div>
//               <label>Email:</label>
//               <input
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleInputChange}
//               />
//               {errors.email && <p className="error">{errors.email}</p>}
//             </div>
//             <div>
//               <label>Degree:</label>
//               <input
//                 type="text"
//                 name="degree"
//                 value={formData.degree}
//                 onChange={handleInputChange}
//               />
//               {errors.degree && <p className="error">{errors.degree}</p>}
//             </div>

//             <div className="form-buttons">
//               <button type="submit" disabled={!isFormValid}>Submit</button>

//               <button
//                 type="button"
//                 onClick={() => setShowForm(false)}
//                 className="close-btn"
//               >
//                 Close
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserForm;

//without page load code 

// import React, { useState, useEffect } from "react";
// import '../../../Pages/Home/Signup/Signupcss.css'

// // Reusable Input and Select Components
// const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
//   <div className="signup-section">
//     <label 
//     htmlFor={id} 
//     className="popupcss-label"
//     >{placeholder}*</label>
//     {type === "select" ? (
//       <select
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         required={required}
//         value={value}
//         onChange={onChange}
//       >
//         <option value="">{placeholder}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         placeholder={placeholder}
//         required={required}
//         value={value}
//         onChange={onChange}
//       />
//     )}
//     {error && <span className="signup-error-message">{error}</span>}
//   </div>
// );

// function SignUp() {
//   const [formData, setFormData] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//     user_course: "",
//     user_location: "",
//     user_available_time: "",
//   });

//   const [errors, setErrors] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//   });

//   const [isFormVisible, setIsFormVisible] = useState(true);
//   const [isAnimated, setIsAnimated] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     // Validation logic
//     let error = "";
//     if (name === "user_name") {
//       const nameRegex = /^[a-zA-Z\s]+$/;
//       error = nameRegex.test(value) ? "" : "Name must contain only letters.";
//     } else if (name === "user_email") {
//       const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
//       error = emailRegex.test(value) ? "" : "Enter a valid email address.";
//     } else if (name === "user_phonenumber") {
//       const phoneRegex = /^\d{10}$/;
//       error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
//     }
//     setErrors((prev) => ({ ...prev, [name]: error }));
//   };

//   const courseOptions = [
//     { value: 'c-programming', label: 'C Programming' },
//     { value: 'cpp-programming', label: 'C++ Programming' },
//     { value: 'java-programming', label: 'Java Programming' },
//     { value: 'react-js', label: 'React JS' },
//     { value: 'python-programming', label: 'Python Programming' },
//     { value: 'machine-learning', label: 'Machine Learning' },
//     { value: 'angular', label: 'Angular' },
//     { value: 'node-js', label: 'Node.js' },
//     { value: 'data-structures', label: 'Data Structures' },
//     { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
//     { value: 'ai', label: 'Artificial Intelligence' },
//     { value: 'testing', label: 'Manual & Automation Testing' },
//     { value: 'blockchain', label: 'Blockchain' },
//     { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
//     { value: 'digital-marketing', label: 'Digital Marketing' },
//     { value: 'fullstack-development', label: 'Full Stack Development' },
//     { value: 'python-fullstack', label: 'Python Fullstack' },
//     { value: 'react-fullstack', label: 'React Fullstack' },
//     { value: 'java-fullstack', label: 'Java Fullstack' },
//     { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
//     { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
//     { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
//     { value: 'content-writing', label: 'Content Writing' },
//     { value: 'embedded-systems', label: 'Embedded Systems' },
//     { value: 'iot', label: 'Internet of Things (IoT)' },
//     { value: 'lt-panel-design', label: 'LT Panel Design' },
//     { value: 'ht-panel-design', label: 'HT Panel Design' },
//   ];

//   const locationOptions = [
//     { value: 'offline', label: 'Offline' },
//     { value: 'online', label: 'Online' },
//   ];

//   const timeOptions = [
//     { value: '9:30am-1pm', label: '9:30am - 1pm' },
//     { value: '1:30pm-7:20pm', label: '1:30pm - 7:20pm' },
//   ];

//   const handleFormClose = () => {
//     setIsFormVisible(false);
//   };

//    // Automatically show form every 10 minutes
//    useEffect(() => {
//     const interval = setInterval(() => {
//       setIsFormVisible(true); // Show the form
//     }, 60000); // 600000 ms = 10 minutes
//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, []);


//   return (
//     isFormVisible && (
//       <form
//         id="signupForm"
//         className={`signupform ${isAnimated ? "animated" : ""}`}
//         method="POST"
//         action="https://formspree.io/f/mqakllpd"
//       >
//         <div className="form-header">
//           <h1 className="signup-heading">Sign Up and Start Your Journey</h1>
//           <button
//             type="button"
//             className="close-button"
//             onClick={handleFormClose}
//           >
//             &times;
//           </button>
//         </div>

//         <div className="signup-container">
//           <div className="signup-wrapper">
//             <FormField
//               type="text"
//               id="name"
//               name="user_name"
//               placeholder="Enter your name"
//               required
//               value={formData.user_name}
//               onChange={handleChange}
//               error={errors.user_name}
//             />
//             <FormField
//               type="email"
//               id="email"
//               name="user_email"
//               placeholder="Enter your email address"
//               required
//               value={formData.user_email}
//               onChange={handleChange}
//               error={errors.user_email}
//             />
//             <FormField
//               type="text"
//               id="phonenumber"
//               name="user_phonenumber"
//               placeholder="Mobile number"
//               required
//               value={formData.user_phonenumber}
//               onChange={handleChange}
//               error={errors.user_phonenumber}
//             />
//             <FormField
//               type="select"
//               id="course"
//               name="user_course"
//               placeholder="Course you are interested in?"
//               options={courseOptions}
//               required
//               value={formData.user_course}
//               onChange={handleChange}
//             />
//             <FormField
//               type="select"
//               id="location"
//               name="user_location"
//               placeholder="Select a location"
//               options={locationOptions}
//               required
//               value={formData.user_location}
//               onChange={handleChange}
//             />
//             <FormField
//               type="select"
//               id="time"
//               name="user_available_time"
//               placeholder="Select a timeslot"
//               options={timeOptions}
//               required
//               value={formData.user_available_time}
//               onChange={handleChange}
//             />
//           </div>
//         </div>
//         <button type="submit" className="signup-submit-button">
//           Submit
//         </button>
//       </form>
//     )
//   );
// }

// export default SignUp;



//with timeoption

// import React, { useState, useEffect } from "react";
// import '../../../Pages/Home/Signup/Signupcss.css'

// // Reusable Input and Select Components
// const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
//   <div className="signup-section">
//     <label 
//     htmlFor={id} 
//     className="popupcss-label"
//     >{placeholder}*</label>
//     {type === "select" ? (
//       <select
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         required={required}
//         value={value}
//         onChange={onChange}
//       >
//         <option value="">{placeholder}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         placeholder={placeholder}
//         required={required}
//         value={value}
//         onChange={onChange}
//       />
//     )}
//     {error && <span className="signup-error-message">{error}</span>}
//   </div>
// );

// function SignUp() {
//   const [formData, setFormData] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//     user_course: "",
//     user_location: "",
//     user_available_time: "",
//   });

//   const [errors, setErrors] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//   });

//   const [isFormVisible, setIsFormVisible] = useState(false); // Form starts hidden
//   const [isAnimated, setIsAnimated] = useState(false);

//   // 30-second delay logic to show the form
//   useEffect(() => {
//     const pageLoadTimeout = setTimeout(() => {
//       setIsFormVisible(true);
//     }, 10000); // 30 seconds delay on page load

//     return () => clearTimeout(pageLoadTimeout);
//   }, []);

//   // Check if 10 minutes have passed after form closure
//   useEffect(() => {
//     const lastClosedTime = localStorage.getItem('lastClosedTime');
//     const currentTime = new Date().getTime();
//     if (!lastClosedTime || currentTime - lastClosedTime > 600000) { // 10 minutes = 600000ms
//       setIsFormVisible(true);
//     }
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     // Validation logic
//     let error = "";
//     if (name === "user_name") {
//       const nameRegex = /^[a-zA-Z\s]+$/;
//       error = nameRegex.test(value) ? "" : "Name must contain only letters.";
//     } else if (name === "user_email") {
//       const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
//       error = emailRegex.test(value) ? "" : "Enter a valid email address.";
//     } else if (name === "user_phonenumber") {
//       const phoneRegex = /^\d{10}$/;
//       error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
//     }
//     setErrors((prev) => ({ ...prev, [name]: error }));
//   };

//   const courseOptions = [
//     { value: 'c-programming', label: 'C Programming' },
//     { value: 'cpp-programming', label: 'C++ Programming' },
//     { value: 'java-programming', label: 'Java Programming' },
//     { value: 'react-js', label: 'React JS' },
//     { value: 'python-programming', label: 'Python Programming' },
//     { value: 'machine-learning', label: 'Machine Learning' },
//     { value: 'angular', label: 'Angular' },
//     { value: 'node-js', label: 'Node.js' },
//     { value: 'data-structures', label: 'Data Structures' },
//     { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
//     { value: 'ai', label: 'Artificial Intelligence' },
//     { value: 'testing', label: 'Manual & Automation Testing' },
//     { value: 'blockchain', label: 'Blockchain' },
//     { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
//     { value: 'digital-marketing', label: 'Digital Marketing' },
//     { value: 'fullstack-development', label: 'Full Stack Development' },
//     { value: 'python-fullstack', label: 'Python Fullstack' },
//     { value: 'react-fullstack', label: 'React Fullstack' },
//     { value: 'java-fullstack', label: 'Java Fullstack' },
//     { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
//     { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
//     { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
//     { value: 'content-writing', label: 'Content Writing' },
//     { value: 'embedded-systems', label: 'Embedded Systems' },
//     { value: 'iot', label: 'Internet of Things (IoT)' },
//     { value: 'lt-panel-design', label: 'LT Panel Design' },
//     { value: 'ht-panel-design', label: 'HT Panel Design' },
//   ];

//   const locationOptions = [
//     { value: 'offline', label: 'Offline' },
//     { value: 'online', label: 'Online' },
//   ];

//   // const timeOptions = [
//   //   { value: '9:30am-1pm', label: '9:30am - 1pm' },
//   //   { value: '1:30pm-7:20pm', label: '1:30pm - 7:20pm' },
//   // ];

//   const handleFormClose = () => {
//     setIsFormVisible(false);
//     // Store the time when the form is closed
//     localStorage.setItem('lastClosedTime', new Date().getTime());
//   };

//   return (
//     isFormVisible && (
//       <form
//         id="signupForm"
//         className={`signupform ${isAnimated ? "animated" : ""}`}
//         method="POST"
//         // action="https://formspree.io/f/mqakllpd"
//         action="https://formspree.io/f/xanyblve"
//       >
//         <div className="form-header">
//           <h1 className="signup-heading">Sign Up and Start Your Journey</h1>
//           <button
//             type="button"
//             className="close-button"
//             onClick={handleFormClose}
//           >
//             &times;
//           </button>
//         </div>

//         <div className="signup-container">
//           <div className="signup-wrapper">
//             <FormField
//               type="text"
//               id="name"
//               name="user_name"
//               placeholder="Enter your name"
//               required
//               value={formData.user_name}
//               onChange={handleChange}
//               error={errors.user_name}
//             />
//             <FormField
//               type="email"
//               id="email"
//               name="user_email"
//               placeholder="Enter your email address"
//               required
//               value={formData.user_email}
//               onChange={handleChange}
//               error={errors.user_email}
//             />
//             <FormField
//               type="text"
//               id="phonenumber"
//               name="user_phonenumber"
//               placeholder="Mobile number"
//               required
//               value={formData.user_phonenumber}
//               onChange={handleChange}
//               error={errors.user_phonenumber}
//             />
//             <FormField
//               type="select"
//               id="course"
//               name="user_course"
//               placeholder="Course you are interested in?"
//               options={courseOptions}
//               required
//               value={formData.user_course}
//               onChange={handleChange}
//             />
//             <FormField
//               type="select"
//               id="location"
//               name="user_location"
//               placeholder="Select a location"
//               options={locationOptions}
//               required
//               value={formData.user_location}
//               onChange={handleChange}
//             />
//             {/* <FormField
//               type="select"
//               id="time"
//               name="user_available_time"
//               placeholder="Select a timeslot"
//               options={timeOptions}
//               required
//               value={formData.user_available_time}
//               onChange={handleChange}
//             /> */}
//           </div>
//         </div>
//         <button type="submit" className="signup-submit-button">
//           Submit
//         </button>
//       </form>
//     )
//   );
// }

// export default SignUp;

//without 10mins

// import React, { useState, useEffect } from "react";
// import '../../../Pages/Home/Signup/Signupcss.css'

// // Reusable Input and Select Components
// const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
//   <div className="signup-section">
//     <label 
//     htmlFor={id} 
//     className="popupcss-label"
//     >{placeholder}*</label>
//     {type === "select" ? (
//       <select
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         required={required}
//         value={value}
//         onChange={onChange}
//       >
//         <option value="">{placeholder}</option>
//         {options.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     ) : (
//       <input
//         type={type}
//         id={id}
//         name={name}
//         className={`signup-input ${error ? 'error' : ''}`}
//         placeholder={placeholder}
//         required={required}
//         value={value}
//         onChange={onChange}
//       />
//     )}
//     {error && <span className="signup-error-message">{error}</span>}
//   </div>
// );

// function SignUp() {
//   const [formData, setFormData] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//     user_course: "",
//     user_location: "",
//   });

//   const [errors, setErrors] = useState({
//     user_name: "",
//     user_email: "",
//     user_phonenumber: "",
//   });

//   const [isFormVisible, setIsFormVisible] = useState(false); // Form starts hidden
//   const [isAnimated, setIsAnimated] = useState(false);

//   // 30-second delay logic to show the form
//   useEffect(() => {
//     const pageLoadTimeout = setTimeout(() => {
//       setIsFormVisible(true);
//     }, 10000); // 30 seconds delay on page load

//     return () => clearTimeout(pageLoadTimeout);
//   }, []);

//   // Check if 10 minutes have passed after form closure
//   useEffect(() => {
//     const lastClosedTime = localStorage.getItem('lastClosedTime');
//     const currentTime = new Date().getTime();
//     if (!lastClosedTime || currentTime - lastClosedTime > 600000) { // 10 minutes = 600000ms
//       setIsFormVisible(true);
//     }
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     // Validation logic
//     let error = "";
//     if (name === "user_name") {
//       const nameRegex = /^[a-zA-Z\s]+$/;
//       error = nameRegex.test(value) ? "" : "Name must contain only letters.";
//     } else if (name === "user_email") {
//       const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
//       error = emailRegex.test(value) ? "" : "Enter a valid email address.";
//     } else if (name === "user_phonenumber") {
//       const phoneRegex = /^\d{10}$/;
//       error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
//     }
//     setErrors((prev) => ({ ...prev, [name]: error }));
//   };

//   const courseOptions = [
//     { value: 'c-programming', label: 'C Programming' },
//     { value: 'cpp-programming', label: 'C++ Programming' },
//     { value: 'java-programming', label: 'Java Programming' },
//     { value: 'react-js', label: 'React JS' },
//     { value: 'python-programming', label: 'Python Programming' },
//     { value: 'machine-learning', label: 'Machine Learning' },
//     { value: 'angular', label: 'Angular' },
//     { value: 'node-js', label: 'Node.js' },
//     { value: 'data-structures', label: 'Data Structures' },
//     { value: 'data-science-analytics', label: 'Data Science/Data Analytics' },
//     { value: 'ai', label: 'Artificial Intelligence' },
//     { value: 'testing', label: 'Manual & Automation Testing' },
//     { value: 'blockchain', label: 'Blockchain' },
//     { value: 'cloud-computing-aws', label: 'Cloud Computing - AWS' },
//     { value: 'digital-marketing', label: 'Digital Marketing' },
//     { value: 'fullstack-development', label: 'Full Stack Development' },
//     { value: 'python-fullstack', label: 'Python Fullstack' },
//     { value: 'react-fullstack', label: 'React Fullstack' },
//     { value: 'java-fullstack', label: 'Java Fullstack' },
//     { value: 'aspnet-core-mvc', label: 'ASP.Net Core MVC Course' },
//     { value: 'hr-payroll-training', label: 'HR & Payroll Training' },
//     { value: 'process-associate', label: 'Process Associate (Supply Chain Management)' },
//     { value: 'content-writing', label: 'Content Writing' },
//     { value: 'embedded-systems', label: 'Embedded Systems' },
//     { value: 'iot', label: 'Internet of Things (IoT)' },
//     { value: 'lt-panel-design', label: 'LT Panel Design' },
//     { value: 'ht-panel-design', label: 'HT Panel Design' },
//   ];

//   const locationOptions = [
//     { value: 'offline', label: 'Offline' },
//     { value: 'online', label: 'Online' },
//   ];

//   const handleFormClose = () => {
//     setIsFormVisible(false);
//     // Store the time when the form is closed
//     localStorage.setItem('lastClosedTime', new Date().getTime());
//   };

//   return (
//     isFormVisible && (
//       <form
//         id="signupForm"
//         className={`signupform ${isAnimated ? "animated" : ""}`}
//         method="POST"
//         // action="https://formspree.io/f/mqakllpd"
//         action="https://formspree.io/f/xanyblve"
//       >
//         <div className="form-header">
//           <h1 className="signup-heading">Sign Up and Start Your Journey</h1>
//           <button
//             type="button"
//             className="close-button"
//             onClick={handleFormClose}
//           >
//             &times;
//           </button>
//         </div>

//         <div className="signup-container">
//           <div className="signup-wrapper">
//             <FormField
//               type="text"
//               id="name"
//               name="user_name"
//               placeholder="Enter your name"
//               required
//               value={formData.user_name}
//               onChange={handleChange}
//               error={errors.user_name}
//             />
//             <FormField
//               type="email"
//               id="email"
//               name="user_email"
//               placeholder="Enter your email address"
//               required
//               value={formData.user_email}
//               onChange={handleChange}
//               error={errors.user_email}
//             />
//             <FormField
//               type="text"
//               id="phonenumber"
//               name="user_phonenumber"
//               placeholder="Mobile number"
//               required
//               value={formData.user_phonenumber}
//               onChange={handleChange}
//               error={errors.user_phonenumber}
//             />
//             <FormField
//               type="select"
//               id="course"
//               name="user_course"
//               placeholder="Course you are interested in?"
//               options={courseOptions}
//               required
//               value={formData.user_course}
//               onChange={handleChange}
//             />
//             <FormField
//               type="select"
//               id="location"
//               name="user_location"
//               placeholder="Select a mode of study"
//               options={locationOptions}
//               required
//               value={formData.user_location}
//               onChange={handleChange}
//             />
//           </div>
//         </div>
//         <button type="submit" className="signup-submit-button">
//           Submit
//         </button>
//       </form>
//     )
//   );
// }

// export default SignUp;













import React, { useState, useEffect } from "react";
import "../../../Pages/Home/Signup/Signupcss.css";

// Reusable Input and Select Components
const FormField = ({ type, id, name, placeholder, options, required, value, onChange, error }) => (
  <div className="signup-section">
    <label htmlFor={id} className="popupcss-label">
      {placeholder}*
    </label>
    {type === "select" ? (
      <select
        id={id}
        name={name}
        className={`signup-input ${error ? "error" : ""}`}
        required={required}
        value={value}
        onChange={onChange}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <input
        type={type}
        id={id}
        name={name}
        className={`signup-input ${error ? "error" : ""}`}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />
    )}
    {error && <span className="signup-error-message">{error}</span>}
  </div>
);

function SignUp() {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phonenumber: "",
    user_course: "",
    user_location: "",
  });

  const [errors, setErrors] = useState({
    user_name: "",
    user_email: "",
    user_phonenumber: "",
  });

  const [isFormVisible, setIsFormVisible] = useState(false); // Form visibility
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    // Show the form 1 minute after page load
    const firstShowTimeout = setTimeout(() => {
      setIsFormVisible(true);
    }, 60000); // 1 minute = 60000ms

    // Show the form every 10 minutes
    const recurringInterval = setInterval(() => {
      const lastClosedTime = localStorage.getItem("lastClosedTime");
      const currentTime = new Date().getTime();

      if (!lastClosedTime || currentTime - lastClosedTime > 600000) { // 10 minutes = 600000ms
        setIsFormVisible(true);
      }
    }, 600000); // Check every 10 minutes

    return () => {
      clearTimeout(firstShowTimeout);
      clearInterval(recurringInterval);
    };
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validation logic
    let error = "";
    if (name === "user_name") {
      const nameRegex = /^[a-zA-Z\s]+$/;
      error = nameRegex.test(value) ? "" : "Name must contain only letters.";
    } else if (name === "user_email") {
      const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      error = emailRegex.test(value) ? "" : "Enter a valid email address.";
    } else if (name === "user_phonenumber") {
      const phoneRegex = /^\d{10}$/;
      error = phoneRegex.test(value) ? "" : "Enter a valid 10-digit phone number.";
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const courseOptions = [
    { value: "c-programming", label: "C Programming" },
    { value: "cpp-programming", label: "C++ Programming" },
    { value: "java-programming", label: "Java Programming" },
    // Add other options here
  ];

  const locationOptions = [
    { value: "offline", label: "Offline" },
    { value: "online", label: "Online" },
  ];

  const handleFormClose = () => {
    setIsFormVisible(false);
    // Store the time when the form is closed
    localStorage.setItem("lastClosedTime", new Date().getTime());
  };

  return (
    isFormVisible && (
      <form
        id="signupForm"
        className={`signupform ${isAnimated ? "animated" : ""}`}
        method="POST"
        action="https://formspree.io/f/xanyblve"
      >
        <div className="form-header">
          <h1 className="signup-heading">Sign Up and Start Your Journey</h1>
          <button type="button" className="close-button" onClick={handleFormClose}>
            &times;
          </button>
        </div>

        <div className="signup-container">
          <div className="signup-wrapper">
            <FormField
              type="text"
              id="name"
              name="user_name"
              placeholder="Enter your name"
              required
              value={formData.user_name}
              onChange={handleChange}
              error={errors.user_name}
            />
            <FormField
              type="email"
              id="email"
              name="user_email"
              placeholder="Enter your email address"
              required
              value={formData.user_email}
              onChange={handleChange}
              error={errors.user_email}
            />
            <FormField
              type="text"
              id="phonenumber"
              name="user_phonenumber"
              placeholder="Mobile number"
              required
              value={formData.user_phonenumber}
              onChange={handleChange}
              error={errors.user_phonenumber}
            />
            <FormField
              type="select"
              id="course"
              name="user_course"
              placeholder="Course you are interested in?"
              options={courseOptions}
              required
              value={formData.user_course}
              onChange={handleChange}
            />
            <FormField
              type="select"
              id="location"
              name="user_location"
              placeholder="Select a mode of study"
              options={locationOptions}
              required
              value={formData.user_location}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit" className="signup-submit-button">
          Submit
        </button>
      </form>
    )
  );
}

export default SignUp;



























// import React, { useState } from "react";
// import "../../../CSS/HomeSlider/Signup.css";

// const UserForm = ({ setShowForm }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     degree: "",
//   });
//   const [errors, setErrors] = useState({});

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };


//   return (
//     <div>
//       <div className="popup-overlay">
//         <div className="popup-form">
//           <h2>Fill Your Details</h2>
//           <form 
//             action="https://formspree.io/f/xdoqwoyg" // Formspree action URL
//             method="POST" // Form submission method
//           >
//             <div>
//               <label>Name:</label>
//               <input
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleInputChange}
//               />
//               {errors.name && <p className="error">{errors.name}</p>}
//             </div>
//             <div>
//               <label>Phone:</label>
//               <input
//                 type="text"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleInputChange}
//               />
//               {errors.phone && <p className="error">{errors.phone}</p>}
//             </div>
//             <div>
//               <label>Email:</label>
//               <input
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleInputChange}
//               />
//               {errors.email && <p className="error">{errors.email}</p>}
//             </div>
//             <div>
//               <label>Degree:</label>
//               <input
//                 type="text"
//                 name="degree"
//                 value={formData.degree}
//                 onChange={handleInputChange}
//               />
//               {errors.degree && <p className="error">{errors.degree}</p>}
//             </div>

//             <div className="form-buttons">
//               <button type="submit">Submit</button>

//               <button
//                 type="button"
//                 onClick={() => setShowForm(false)}
//                 className="close-btn"
//               >
//                 Close
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserForm;
