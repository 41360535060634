import React from 'react';
import Service from '../../Services/Service';
import Serv from '../../Services/Serv';
import LayOut from '../../Home/NavigationBar/LayOut'


export default function Services() {
  
  return (
<LayOut> 
    <div className='courses-container'>
       <Service />
     <Serv />    

    </div>
    </LayOut>
  )
}




