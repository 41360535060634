import React from "react";
import { useState, useEffect, useRef } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Popup from "reactjs-popup";
import Button from '@mui/material/Button';
import '../CSS/Popup/ServicesContent.css';
import '.././Pages/AboutUs/aboutUs.css'


const ModalWindow = ({ triggerText, customContent }) => {
  const [popupWidth, setPopupWidth] = useState('80%');
  const [isModalOpen, setIsModalOpen] = useState(false); // Default width
  const popupContainerRef = useRef(null);//


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 600) { // Adjust the width for mobile devices
        setPopupWidth('90%');
      } else {
        setPopupWidth('60%'); // Default width for larger screens
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePopupOpen = () => {
    // Scroll to the top of the popup container when it's opened
    // const popupContainer = document.querySelector('.popup-display');
    // if (popupContainer) {
    //   popupContainer.scrollTop = 0;
    // }
    if (popupContainerRef.current) {
      popupContainerRef.current.scrollTop = 0;
    }
  };
  
  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };


  return (
    <Popup  trigger={
    <div>  <Button variant="contained" color="primary" onClick={openModal}>{triggerText}</Button> </div>} 
    modal nested  open={isModalOpen}
    onClose={closeModal}
     closeOnDocumentClick={false}
      contentStyle={{ width:popupWidth}}
       onOpen={handlePopupOpen}
       >
      {(close) => (
        
        
        <div className="popup-container"  ref={popupContainerRef}>
          <div className="closePopupButton" onClick={close}><IoMdCloseCircle /></div>
          
          <div className="popup-display">
            {customContent.map((section, index) => (
              <div key={index}>
                {section.heading && <h2 className="page-heading">{section.heading}</h2>}
                {section.title && <h3>{section.title}</h3>}
                {section.subTitle && <h4>{section.subTitle}</h4>}
                {section.paragraph && <p className="page-paragraph">{section.paragraph}</p>}
              </div>
            ))}
          </div>
          <div className="popup-button">
            <Button variant="contained" color="primary" onClick={() => close()}>
              Close
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ModalWindow;


